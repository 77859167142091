import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from "react";
import SharedButton from '../../components/shared/SharedButton/SharedButton';
import SharedDrawerMentee from '../../components/shared/SharedDrawerMentee/SharedDrawerMentee';
import { Title, TitleWrapper } from './styles';
import Login from '../../components/login/Login';
import EditMenteeProfile from '../../components/editmenteeprofile/EditMenteeProfile';
import { baseURL } from '../../config/config';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

function MenteeEditProfile({ setToken, token }) {
    return token ? (
        <>
            <SharedDrawerMentee token={token} />
            <Grid container padding={"0 4vw"}>
                <TitleWrapper container>
                    <Grid item xs={8}>
                        <Stack direction="row" spacing={10}>
                            <Title>Mentee profile</Title>
                            <Link href="/mentee/profile">
                                <SharedButton buttonType="Action" width="Long">View profile</SharedButton>
                            </Link>
                        </Stack>
                    </Grid>
                </TitleWrapper>
                <EditMenteeProfile token={token} />
            </Grid>
        </>
    ) : (
        <Login setToken={setToken} />
    );
}

export default MenteeEditProfile;