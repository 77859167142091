import React, { useEffect, useState } from "react";
import { MenuItem, Select, TextField } from "@mui/material";
import {
    RegistrationContainer,
    TitleContainer,
    InputContainer,
    Title,
    Label
} from "./styles";
import { baseURL } from "../../../config/config";

async function getTopics(token) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    }

    return fetch(baseURL + "/topics", request)
        .then((res) => res.json());
}

function MentorProfile(props) {
    const {
        handleChange,
        handleTopicsChange,
        handleMetadataChange,
        values,
        errors,
        cToken
    } = props;

    const {
        topics,
        eduStyle,
        metadata
    } = values;

    const {
        topicsError,
        eduStyleError
    } = errors;

    useEffect(() => {
        getData(cToken);
    }, []);

    const [cTopics, setcTopics] = useState({});


    const getData = async (token) => {
        const topics = await getTopics(cToken)
            .then((res) => {
                if (res.responseCode === 200) {
                    setcTopics(res.data);
                }
            })
    }

    const areas = Object.keys(cTopics).map((key) => {
        return (
            <MenuItem value={key}>{cTopics[key]}</MenuItem>
        )
    }
    );

    return (
        <RegistrationContainer>
            <TitleContainer>
                <Title>Mentor Profile</Title>
            </TitleContainer>
            <InputContainer>
                {/* TODO: Change these to topics from API */}
                <Label>Area(s) of Expertise</Label>
                <Select
                    label="Area of Expertise"
                    id="area-of-expertise"
                    error={topicsError}
                    value={topics}
                    onChange={handleTopicsChange}
                    helperText={topicsError ? "Please choose the areas you wish to mentor others in" : ""}
                    fullWidth
                    required
                    select
                    multiple
                >
                    {areas}
                </Select>
            </InputContainer>
            <InputContainer>
                <Label>What is your teaching style?</Label>
                <TextField
                    label="Teaching Style"
                    id="edu-style"
                    name="eduStyle"
                    value={eduStyle}
                    error={eduStyleError}
                    onChange={handleMetadataChange("eduStyle")}
                    helperText={eduStyleError ? "Please choose your preferred teaching style" : ""}
                    fullWidth
                    required
                    select
                >
                    <MenuItem value="Visual">I like using visual tools when teaching</MenuItem>
                    <MenuItem value="Auditory">I like using auditory tools when teaching</MenuItem>
                    <MenuItem value="Kinesthetic">I like using kinesthetic tools when teaching</MenuItem>
                </TextField>
            </InputContainer>
        </RegistrationContainer>
    )
}

export default MentorProfile;