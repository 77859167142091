import { Grid, Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from "react";
import SharedButton from '../../components/shared/SharedButton/SharedButton';
import SharedDrawerMentee from '../../components/shared/SharedDrawerMentee/SharedDrawerMentee';
import { Title, TitleWrapper } from './styles';
import Login from '../../components/login/Login';
import PlanOfActionFull from '../../components/planofaction/PlanOfActionFull';
import { baseURL } from '../../config/config';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

function PlanOfAction({ setToken, token }) {
    return token ? (
        <>
            <SharedDrawerMentee token={token} />
            <Grid container padding={"0 4vw"}>
                <TitleWrapper container>
                    <Grid item xs={10} lg={5} >
                        {/* <Stack direction="row" spacing={10}> */}
                        <Title>Plan of action</Title>
                    </Grid>
                    <Grid item xs={2.3} lg={5}>
                        <Stack direction="row" spacing={3}>
                            <Box sx={{ boxShadow: 2 }}>
                                <Link href="/mentee/planofaction/add">
                                    <SharedButton buttonType="Action" width="Long">Add Milestone</SharedButton>
                                </Link>
                            </Box>
                            <Box sx={{ boxShadow: 2 }}>
                                <Link href="/mentee/planofaction/addtask">
                                    <SharedButton buttonType="Action" width="Long">Add Task</SharedButton>
                                </Link>
                            </Box>
                        </Stack>
                    </Grid>
                </TitleWrapper>
                <PlanOfActionFull token={token} />
            </Grid>
        </>
    ) : (
        <Login setToken={setToken} />
    );
}

export default PlanOfAction;