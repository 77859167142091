import React, { Component } from "react";
import { convertDate } from '../../../util';

class SharedDate extends Component {
    constructor() {
        super();
        this.state = {
            currentDateTime: Date().toLocaleString()
        }
    }
  
    render() {
        return (convertDate(this.state.currentDateTime));
    }
}

export default SharedDate;