import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Grid, Box } from '@mui/material';
import { baseURL } from '../../config/config';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Rating from '@mui/material/Rating';
import {
	FieldWrapper,
	FormLabel,
	HeaderOne,
	HeaderTwo,
	TinyText,
	CreateMeetingWrapper,
	SelectWrapper
} from "./styles";

/* NEED TO ADD: -JSON REQUEST TO GET LIST OF TOPICS TO FILL THE TOPIC DROP DOWN
				-STORE MENTORID TO INCLUDE IN JSON POST
*/

// Call API to get user's name and image
async function getUserInfo(params) {
	const auth_header = "Bearer " + params.loginToken;
	const request = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
	};

	var response = await fetch(baseURL + "/profile", request)
	return response.json();
}

async function submitMentorProfile(params) {
	const auth_header = "Bearer " + params.loginToken;

	// Submit to mentee profile first
	const toSubmit = {
		"bio": params.values.profiledescription,
		"topics": [],
		"metadata": "",
		"takingMoreBookings": true
	};
	const request = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
		body: JSON.stringify(toSubmit)
	};
	var mentorResponse = await fetch(baseURL + "/profile/mentor", request)

	// Submit to user profile second
	const toSubmit2 = {
		"firstName": params.values.firstname,
		"secondName": params.values.lastname,
		"role": params.values.learningstyle,
		"jobPosition": params.values.jobtitle,
		"jobDateStarted": "02/03/2022",
		"profilePicture": params.values.profilephoto,
		"location": params.values.location,
		"department": params.values.businessarea
	}
	console.log(toSubmit2);
	const request2 = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
		body: JSON.stringify(toSubmit2)
	};
	var userResponse = await fetch(baseURL + "/profile/user", request2)
	return userResponse.json();
}

async function getProficiencies(params) {
	const auth_header = "Bearer " + params.loginToken;
	const request = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
	};

	var response = await fetch(baseURL + "/matching/proficiency", request)
	return response.json();
}

async function postProficiencies(params) {
	const auth_header = "Bearer " + params.loginToken;
	const toSubmit = [];
	if (params.values.p1) { toSubmit.push({ "topicid": 1, "skillrating": params.values.p1 }); }
	if (params.values.p2) { toSubmit.push({ "topicid": 2, "skillrating": params.values.p2 }); }
	if (params.values.p3) { toSubmit.push({ "topicid": 3, "skillrating": params.values.p3 }); }
	if (params.values.p4) { toSubmit.push({ "topicid": 4, "skillrating": params.values.p4 }); }

	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
		body: JSON.stringify(toSubmit)
	};

	var response = await fetch(baseURL + "/matching/proficiency", request)
	return response.json();
}

const defaultValues = {
	firstname: "",
	lastname: "",
	location: "",
	businessarea: "",
	jobtitle: "",
	jobstartdate: "",
	profilephoto: "",
	profiledescription: "",
	learningstyle: "",
	submitText: "Save profile information"
};

const EditMentorProfile = ({ token }) => {

	const businessAreaOptions = ["Marketing", "Finance", "Operations Management", "Human Resource", "IT"];
	const learningStyleOptions = ["Visual", "Auditory", "Kinesthetic"];
	const proficiencies = [
		{ name: "Time management", id: "p1" },
		{ name: "Leadership", id: "p2" },
		{ name: "Organisation", id: "p3" },
		{ name: "Project Management", id: "p4" }
	];

	// add in extra proficiencies for rating
	const theseValues = {
		...defaultValues
	};
	proficiencies.forEach(proficiency => {
		theseValues[proficiency] = 2;
	});

	const [values, setValues] = useState(defaultValues);

	useEffect(() => {
		getData();
	}, []);

	var getData = async () => {
		const res = await getUserInfo({ loginToken: token });
		if (res.responseCode === 200) {
			const res2 = await getProficiencies({ loginToken: token });
			const pstoadd = {}
			res2.forEach(proficiency => {
				pstoadd["p" + proficiency.topicid] = proficiency.skillrating;
			});
			setValues({
				...values,
				...pstoadd,
				firstname: res.data.firstname,
				lastname: res.data.secondname,
				learningstyle: res.data.role,
				location: res.data.location,
				jobtitle: res.data.jobposition,
				businessarea: res.data.department,
				jobstartdate: res.data.jobdatestarted,
				profilephoto: res.data.profilepicture,
				profiledescription: res.data.mentorProfile.bio
			});
		}
	}

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setValues({...values, [name]: value});
	};

	const handleStarChange = (name) => (e, value) => {
		setValues({...values, [name]: value});
	};

	var handleSubmit = async (e) => {
		e.preventDefault();
		const res = await submitMentorProfile({ loginToken: token, values: values });
		if (res.responseCode === 200) {
			const res2 = await postProficiencies({ loginToken: token, values: values });
			if (res2.responseCode === 200) {
				setValues({...values, submitText: "Saved successfully 😁"});
			} else {
				setValues({...values, submitText: "Error saving proficiencies 😞"});
			}
		} else {
			setValues({...values, submitText: "Error 😞 Is every field filled?"});
		}
	}

	return (
		<>
			<CreateMeetingWrapper container>
				<Grid xs={12}>
					<Grid container>
						<Grid item xs={8}>
							<HeaderOne>Edit mentor profile</HeaderOne>
						</Grid>
						<Grid item xs={4}>
							<Box display="flex" flexDirection="column" alignItems="center">
								{/* Material UI has loadingButton can add later for better UX */}
								<Button
									onClick={handleSubmit}
									variant="contained"
									sx={{ mt: 3, mb: 2, color: "#FFFFFF" }}
								>
									{values.submitText}
								</Button>
							</Box>
						</Grid>

						<Grid item xs={12} lg={6}>
							<Box sx={{ border: 1, borderColor: '#000000',
								borderRadius: 3, padding: 2, margin: 1, boxShadow: 2 }}
							>
								<HeaderTwo>Basic Information</HeaderTwo>
								<FieldWrapper>				
									<FormLabel>First Name</FormLabel>
									<TextField 
										id="firstname"
										name="firstname"
										type="text"
										value={values.firstname}
										onChange={handleInputChange}
										fullWidth
										required
									/>
								</FieldWrapper>

								<FieldWrapper>				
									<FormLabel>Last Name</FormLabel>
									<TextField 
										id="lastname"
										name="lastname"
										type="text"
										value={values.lastname}
										onChange={handleInputChange}
										fullWidth
										required
									/>
								</FieldWrapper>

								<FieldWrapper>				
									<FormLabel>What is your location?</FormLabel>
									<TextField 
										id="location"
										name="location"
										type="text"
										value={values.location}
										onChange={handleInputChange}
										fullWidth
										required
									/>
								</FieldWrapper>

								<FieldWrapper>				
									<FormLabel>What is your department?</FormLabel>
									<TextField 
										id="businessarea"
										name="businessarea"
										type="text"
										value={values.businessarea}
										onChange={handleInputChange}
										SelectProps={{
											native: true,
										}}							
										fullWidth
										required
										select
									>
										<option value="" />
										{businessAreaOptions.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</TextField>
								</FieldWrapper>

								<FieldWrapper>				
									<FormLabel>What is your job title?</FormLabel>
									<TextField 
										id="jobtitle"
										name="jobtitle"
										type="text"
										value={values.jobtitle}
										onChange={handleInputChange}
										fullWidth
										required
									/>
								</FieldWrapper>
							
								<FieldWrapper>
									<FormLabel>When did you start your job?</FormLabel>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											value={values.jobstartdate}
											onChange={(date) => setValues({...values, jobstartdate: date})}
											renderInput={(params) => <TextField {...params} fullWidth required />}
										/>
									</LocalizationProvider>
								</FieldWrapper>
								
								<FieldWrapper>				
									<FormLabel>Profile Description</FormLabel>
									<TextField 
										id="profiledescription"
										name="profiledescription"
										type="text"
										value={values.profiledescription}
										onChange={handleInputChange}
										fullWidth
										required
										multiline
										rows="5"
										helperText="Make sure to tell us about yourself, your hobbies and what you have to offer!"
									/>
								</FieldWrapper>
							</Box>
						</Grid>

						<Grid item xs={12} lg={6}>
							<Box sx={{ border: 1, borderColor: '#000000',
								borderRadius: 3, padding: 2, margin: 1, boxShadow: 2 }}
							>
								<HeaderTwo>More Profile Information</HeaderTwo>
								<FormLabel>This information helps us find a perfect match for your mentees!</FormLabel>

								<FieldWrapper>				
									<FormLabel>Teaching Style</FormLabel>
									<TextField 
										id="learningstyle"
										name="learningstyle"
										type="text"
										value={values.learningstyle}
										onChange={handleInputChange}
										SelectProps={{
											native: true,
										}}							
										fullWidth
										required
										select
									>
										<option value="" />
										{learningStyleOptions.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</TextField>
								</FieldWrapper>


								<FormLabel>How good are you at the moment at each of the following skills?</FormLabel>
									
								{proficiencies.map(p => (
									<FieldWrapper>
										<FormLabel>{p.name}</FormLabel>
										<TinyText>{values[p.id] ? ( "Currently " + values[p.id] )
											: ("We haven't heard about your " + p.name.toLowerCase() + " ability yet!")}
										</TinyText>
										<Rating
											size="large"
											name={p.id}
											value={values[p.id]}
											onChange={handleStarChange(p.id)}
											helperText="How good are you at this skill?"
										/>
								</FieldWrapper>
								))}

							</Box>
						</Grid>
					</Grid>
				</Grid>

			</CreateMeetingWrapper>
		</>
	)
}

export default EditMentorProfile;