import React, { useState } from "react";
import SharedButton from "../SharedButton/SharedButton";
import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import BasicModal from "../../modal/BasicModal";
import { baseURL } from "../../../config/config";

// Call API to get user's name and image
async function mentorTerminateRelationship(params) {
  const auth_header = "Bearer " + params.loginToken;
  const toSubmit = {
		menteeUserID: parseInt(params.menteeUserID)
	};

  const request = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
    body: JSON.stringify(toSubmit)
  };

  var response = await fetch(baseURL + "/matching/mentorterminate", request)
  return response.json();
}

function TerminateRelationship(token, menteeUserID) {
  (async () => {
    let results = await mentorTerminateRelationship({ loginToken: token, menteeUserID: menteeUserID });
  })();
}

function MentorTerminate({ token, menteeUserID }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <>
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <SharedButton buttonType="Terminate" width="Long" onClick={() => {setOpen(true);}}>Terminate Relationship</SharedButton>
        <BasicModal open={open} setOpen={setOpen}>
          <Typography id="modal-modal-title" variant="h6" component="h2">Are you sure you are ready to terminate this relationship?</Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>This will remove your current plan of action.</Typography>
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop:'30px'}}>
            <SharedButton buttonType="Terminate" width="Long" onClick={() => TerminateRelationship(token, menteeUserID)}>Confirm Termination</SharedButton>
          </div>
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop:'30px'}}>
            <SharedButton buttonType="Action" width="Long" onClick={() => {navigate('/mentor/home');}}>Back to Dashboard</SharedButton>
          </div>
        </BasicModal>
      </div>
    </>
  );
}

export default MentorTerminate;
