import React from "react";
import ChooseMentor from "../../components/choose_mentor/ChooseMentor";
import SharedDrawerMentee from "../../components/shared/SharedDrawerMentee/SharedDrawerMentee";

function ChooseMentorPage({token}) {
    return (
        <>
            <SharedDrawerMentee token={token} />
            <ChooseMentor token={token}/>
        </>
    )
}

export default ChooseMentorPage;