import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from "react";
import SharedButton from '../../components/shared/SharedButton/SharedButton';
import SharedDrawerMentee from '../../components/shared/SharedDrawerMentee/SharedDrawerMentee';
import { Title, TitleWrapper } from './styles';
import Login from '../../components/login/Login';
import AddMilestone from '../../components/planofaction/AddMilestone';
import { baseURL } from '../../config/config';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

function MilestoneEdit({ setToken, token }) {
    return token ? (
        <>
            <SharedDrawerMentee token={token} />
            <Grid container padding={"0 4vw"}>
                <TitleWrapper container>
                    <Grid item xs={8}>
                        <Stack direction="row" spacing={10}>
                            <Title>Plan of action</Title>
                            <Link href="/mentee/planofaction">
                                <SharedButton buttonType="Action" width="Long">View plan</SharedButton>
                            </Link>
                        </Stack>
                    </Grid>
                </TitleWrapper>
                <AddMilestone token={token} />
            </Grid>
        </>
    ) : (
        <Login setToken={setToken} />
    );
}

export default MilestoneEdit;