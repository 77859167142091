import React, {useState, useEffect} from 'react';
import { CardContent, Grid } from "@mui/material";
import { ButtonWrapper, CardStyled, CardTitle, LinearProgressStyled, ProgressBarWrapper, ProgressLabel } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import { baseURL } from '../../config/config';

async function getPlan(params) {
	const auth_header = "Bearer " + params.loginToken;
	const request = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
	};
	var response = await fetch(baseURL + "/feedback/myplan", request)
	return response.json();
}

const Milestone = ({ milestoneID, tasks, token }) => {
    const [tasksNo, setTasksNo] = useState(0);
    const [completeTasks, setCompleteTasks] = useState(0);
    const [title, setTitle] = useState("[No Title]");

    useEffect(() => {
        getData();
    }, []);

    var getData = async () => {
        const res = await getPlan({ loginToken: token });
        if (res.responseCode === 200) {
            const theseTasks = [];
            for (let taskindex in res.data) {
                if (tasks[taskindex].milestoneid === milestoneID) {
                    setTitle(tasks[taskindex].milestonename);
                    theseTasks.push(tasks[taskindex]);
                }
            }
            setTasksNo(theseTasks.length);
            setCompleteTasks(theseTasks.filter(task => task.taskstatus === "Complete").length);
        }
    };

    const HomeMilestone = (props) => {
        return (
            <CardStyled variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <CardTitle>{title ? title : "[No title]"}</CardTitle>
                                <ProgressBarWrapper container>
                                    <Grid item xs={8}>
                                        <LinearProgressStyled variant="determinate" color="secondary" value={100*(completeTasks/tasksNo)} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        { (100*(completeTasks/tasksNo) === 100)
                                            ? (<ProgressLabel>Completed!</ProgressLabel>)
                                            : (<ProgressLabel>{completeTasks} { (completeTasks === 1) ? "task" : "tasks"} complete</ProgressLabel>) }
                                    </Grid>
                                </ProgressBarWrapper>
                            </Grid>
                        </Grid>
                </CardContent>
            </CardStyled>
        )
    }
    return (
        <HomeMilestone />
    )
}

export default Milestone;