import { Grid } from "@mui/material";
import React from "react";
import Login from "../../../components/login/Login";
import MyMentees from "../../../components/mentees/MyMentees/MyMentees";
import SharedDrawerMentor from "../../../components/shared/SharedDrawerMentor/SharedDrawerMentor";
import { 
    Title, 
    TitleWrapper 
} from "./styles";

function MyMenteesPage(token, setToken) {
    return (
        token ? (
            <>
                <SharedDrawerMentor token={token} />
                <Grid container padding={"0 4vw"}>
                    <TitleWrapper container>
                        <Grid item xs={12}>
                            <Title>My Mentees</Title>
                        </Grid>
                        <MyMentees />
                    </TitleWrapper>
                </Grid>
            </>
        ) : (
            <Login setToken={setToken} />
        )
    );
}

export default MyMenteesPage;