import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from "react";
import SharedButton from '../../components/shared/SharedButton/SharedButton';
import SharedDrawerMentor from '../../components/shared/SharedDrawerMentor/SharedDrawerMentor';
import { Title, TitleWrapper } from './styles';
import Login from '../../components/login/Login';
import EditMentorProfile from '../../components/editmentorprofile/EditMentorProfile';
import { baseURL } from '../../config/config';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

function MentorEditProfile({ setToken, token }) {
    return token ? (
        <>
            <SharedDrawerMentor token={token} />
            <Grid container padding={"0 4vw"}>
                <TitleWrapper container>
                    <Grid item xs={8}>
                        <Stack direction="row" spacing={10}>
                            <Title>Mentor profile</Title>
                            <Link href="/mentor/profile">
                                <SharedButton buttonType="Action" width="Long">View profile</SharedButton>
                            </Link>
                        </Stack>
                    </Grid>
                </TitleWrapper>
                <EditMentorProfile token={token} />
            </Grid>
        </>
    ) : (
        <Login setToken={setToken} />
    );
}

export default MentorEditProfile;