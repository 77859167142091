import React from "react";
import { useLocation, useMatch, useParams } from "react-router-dom";
import SharedDrawerMentor from '../../../components/shared/SharedDrawerMentor/SharedDrawerMentor';
import TerminateRelationship from '../../../components/shared/TerminateRelationship/MentorTerminate';
import User from "../../../components/user/User";

function MenteePage() {
    const params = useParams();
    const menteeUserID = params?.id;
    const token = JSON.parse(localStorage.getItem('token'));

    return (
        <>
            <SharedDrawerMentor token={token} />
            <User
                userId={menteeUserID}
                role={"mentee"}
                view={"page"}
                token={token}
            />
            <TerminateRelationship token={token} menteeUserID={menteeUserID}/>
        </>
    );
}

export default MenteePage;