import styled from 'styled-components';
import { FontSize } from '../../assets/font';
import { 
    Grid, 
    Typography 
} from '@mui/material';

// Typography
export const HeaderOne = styled(Typography)`
    && {
        font-size: ${FontSize.header1};
        font-weight: 700;
    }
`;

export const FormLabel = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
        margin-bottom: 10px;
    }
`;

// Grid
export const CreateMeetingWrapper = styled(Grid)`
    && {
        margin: 0 50px;
        width: 100%;
        padding: 10px auto;
        min-width: 850px;
    }
`;

export const FormWrapper = styled(Grid)`
    && {
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 15vh 20px;
    }
`;

export const FieldWrapper = styled(Grid)`
    && {
        padding: 20px 0;
        max-width: 800px;
    }
`;

export const DateWrapper = styled(Grid)`
    && {
        max-width: 800px;
    }
`;

export const ButtonWrapper = styled(Grid)`
    && {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
`;

export const SelectWrapper = styled(Grid)`
    && {
        font-size: 20px;
        max-width: 800px;
    }
`

