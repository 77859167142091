import React, { useEffect, useState } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import DetailedMeeting from "../meetings/DetailedMeeting";
import { Date, DateSessions, MyMeetingList, Title } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import SharedDate from "../shared/SharedDate/SharedDate";
import { baseURL } from '../../config/config';
import { convertDate } from '../../util';

async function allMeetings(token) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

	var response = await fetch(baseURL + "/event/allgroupsessions", request);
    var json = await response.json() ?? {};
    
    if (json.responseCode !== 200) return [];
    return json.data;
}

function AllGroupSessions({ token }) {
    
    const [meetingsRequest, setMeetingsRequest] = useState([]);
    
    let refresh = (async () => {
        let meetingsRequest = await allMeetings(token);
        if (meetingsRequest != null) setMeetingsRequest(meetingsRequest);
    });

    useEffect(() => {
        refresh();
    }, []);

    let meetingDisplay = [meetingsRequest.length];
    if (meetingsRequest.length) {
        let i = 0;
        meetingsRequest.map((meeting) => {
            if (meeting.userIsParticipant) return;

            meetingDisplay[i] = <DetailedMeeting
            token={token}
            title={meeting.description}
            type={meeting.type}
            name={meeting.mentorfname + " " + meeting.mentorsname}
            date={meeting.proposeddate}
            venue={meeting.location}
            mentorid={meeting.mentorid}
            id={meeting.sessionid}
            key={meeting.sessionid}
            mode="mentee"
            status={meeting.status}
            decor={meeting.maxparticipants - meeting.currentparticipants + " spaces left"}
            update={refresh}
            participants={meeting.participants}
            isparticipant={meeting.userIsParticipant} 
            finishdate={meeting.finishdate} 
            />
            i++;
        })
    } else {
        meetingDisplay = (<Typography>There are no group sessions available.</Typography>)
    }

    return (
        <MyMeetingList>
            <DateSessions container>
                {meetingDisplay}
            </DateSessions>
        </MyMeetingList>
    )
}

export default AllGroupSessions;