import React, { useState, useEffect } from 'react';
import { baseURL } from '../../../config/config';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { ListItem, ListItemIcon, ListItemText, ListItemButton} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SettingsIcon from '@mui/icons-material/Settings';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import SharedButton from '../SharedButton/SharedButton';
import logo from '../../../static/images/MentorMe_logo.png';
import { Grid } from '@mui/material';
import {
  DrawerContainer,
  IconContainer,
  IconTextContainer,
  LinkStyled,
  LinksWrapper,
  LogoWrapper,
  MainActionsWrapper,
  SettingsWrapper,
  Title,
  TopBar
} from './styles';
import { useNavigate } from 'react-router-dom';

// Call API to get user's name and image
async function getUserInfo(params) {
  const auth_header = "Bearer " + params.loginToken;
  const request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
  };

  var response = await fetch(baseURL + "/profile", request)
  return response.json();
}

// Make sure an image URL is valid
function isValidImage(url) {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
}

const SharedDrawerMentor = ({ setToken, token }) => {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [canSwitch, setCanSwitch] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getData();
  }, []);

  var getData = async () => {
    const res = await getUserInfo({ loginToken: JSON.parse(localStorage.getItem('token')) });
    if (res.responseCode === 200) {
      setName(res.data.firstname + " " + res.data.secondname);
      setImage(res.data.profilepicture); // if invalid, default profile picture will be used
      setCanSwitch(res.data.menteeProfile !== null);
    }
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <>
      <LogoWrapper>
        <img
          src={logo}
          width="180px"
        />
      </LogoWrapper>
      <DrawerContainer
        sx={{ width: 350 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >

        <Grid container margin={"10px"} alignItems={"center"} spacing={2}>
          <Grid item xs>
            <Avatar sx={{ width: "5vw", height: "5vw" }} alt={name} src={image} />
          </Grid>
          <MainActionsWrapper item xs={8}>
            <Title>{name}</Title>
            <IconTextContainer>
              <IconContainer><PersonIcon /></IconContainer>
              <LinkStyled href="/mentor/profile" variant='body1'>View Profile</LinkStyled>
            </IconTextContainer>
            <IconTextContainer>
              <IconContainer><LogoutIcon /></IconContainer>
              <LinkStyled onClick={() => {localStorage.setItem('token', null); setToken(null)}} href="/login" variant='body1'>Log Out</LinkStyled>
            </IconTextContainer>
          </MainActionsWrapper>
        </Grid>
        <LinksWrapper>
          <IconTextContainer space="true">
            <IconContainer><DashboardIcon /></IconContainer>
            <LinkStyled href="/mentor/home" variant='body1'>Dashboard</LinkStyled>
          </IconTextContainer>
          <IconTextContainer space="true">
            <IconContainer><SupervisorAccountIcon /></IconContainer>
            <LinkStyled href="/mentor/my_mentees" variant='body1'>My Mentees</LinkStyled>
          </IconTextContainer>
          <IconTextContainer space="true">
            <IconContainer><MeetingRoomIcon /></IconContainer>
            <LinkStyled href="/mentor/meetings" variant='body1'>My Meetings</LinkStyled>
          </IconTextContainer>
        </LinksWrapper>


        <SettingsWrapper>
          {canSwitch ?
            <IconTextContainer space="true">
              <IconContainer><SwitchAccountIcon /></IconContainer>
              <LinkStyled onClick={() => localStorage.setItem('role', "mentee")} href="/mentee/home" variant='body1'>Switch to Mentee Platform</LinkStyled>
            </IconTextContainer>
            :
            <IconTextContainer space="true">
              <IconContainer><SwitchAccountIcon /></IconContainer>
              <LinkStyled variant='body1' onClick={() => navigate("/register", { state: { role: "mentee", token } })}>Register as a Mentee</LinkStyled>
            </IconTextContainer>
          }
          <IconTextContainer space="true">
            <IconContainer><SettingsIcon /></IconContainer>
            <LinkStyled href="/" variant='body1'>Settings</LinkStyled>
          </IconTextContainer>
          <IconTextContainer space="true">
            <SharedButton
              buttonType={"Action"}
              width={"Long"}
            >
              <IconContainer><RateReviewIcon /></IconContainer>
              <LinkStyled href="/feedback" variant='body1'>Provide Feedback</LinkStyled>
            </SharedButton>
          </IconTextContainer>
        </SettingsWrapper>
      </DrawerContainer>
    </>
  );

  return (
    <>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <TopBar>
            <SharedButton buttonType="Menu" onClick={toggleDrawer('left', true)}><MenuIcon /></SharedButton>
          </TopBar>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
}

export default SharedDrawerMentor;