import { Grid } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import CreateGroupSessionWidget from '../../components/groupsessions/widgets/CreateGroupSession/CreateGroupSessionWidget';
import Login from '../../components/login/Login';
import MyMeetingsHome from '../../components/meetings/MyMeetingsHome';
import MenteeList from '../../components/mentees/MenteeList';
import SharedDrawerMentor from '../../components/shared/SharedDrawerMentor/SharedDrawerMentor';
import { Title, TitleWrapper } from './styles';
import NotificationList from '../../components/notifications/NotificationList';
import { Subtitle } from '../../components/groupsessions/widgets/GroupSessionList/styles';
import SharedErrorDialog from '../../components/shared/SharedErrorDialog/SharedErrorDialog';

function MentorHome({ setToken, token }) {
    const { state } = useLocation();
    const [show, setShow] = useState(false);

    if (!token) {
        return <Navigate to='/login' />
    }

    if (state?.message != null && !show) {
        setShow(true);
    }

    return (
        <>
        {console.log(localStorage.getItem('role'))}
            <SharedDrawerMentor token={token} />
            <Grid container justifyContent={"center"} padding={"0 4vw"}>
                <TitleWrapper container>
                    <Grid item xs={12}>
                        <Title>Dashboard</Title>
                        <SharedErrorDialog
                            $error={false}
                            message={state?.message ?? ""}
                            $show={show}
                            toggleDialog={() => { state.message = null; setShow(false) }} />
                    </Grid>
                </TitleWrapper>
                <Grid container justifyContent={"center"}>
                    <CreateGroupSessionWidget item xs={6} />
                    <NotificationList item xs={6} token={token} />
                    <MenteeList item xs={6} token={token} />
                    <MyMeetingsHome item xs={6} token={token} mentor={true} />
                </Grid>
            </Grid>

        </>
    );
}

export default MentorHome;