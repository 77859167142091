import React from "react";
import { Avatar, Box, CardContent, Grid } from "@mui/material";
import { AvatarContainer, ButtonWrapper, CardStyled, CardSubtitle, CardTextContainer, CardTitle } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";

function Notification(props) {
    const { title, subtitle, profile, update } = props;

    return (
        <CardStyled variant="outlined">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs gridColumn={1}>
                        <Box display="flex" flexDirection="row">
                            <AvatarContainer>
                                <Avatar sx={{ width: 50, height: 50}} alt="Profile picture" src={profile}>R</Avatar>
                            </AvatarContainer>
                            <CardTextContainer paddingLeft={2} paddingRight={2} minWidth={"160px"}>
                                <CardTitle>{title ? title : ""}</CardTitle>
                                <CardSubtitle>{subtitle ? subtitle : ""}</CardSubtitle>
                            </CardTextContainer>
                        </Box>
                    </Grid>
                    <Grid item gridColumn={2}>
                        <ButtonWrapper>
                            <SharedButton buttonType="Action" width="Short" onClick={update}>Dismiss</SharedButton>
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </CardContent>
        </CardStyled>
    )
}

export default Notification;