import React from 'react';
import RegistrationSteps from '../../components/register/RegistrationSteps/RegistrationSteps';
import RegisterMain from '../../components/register/RegisterMain/RegisterMain';
import Login from '../../components/login/Login';


function Register({setToken}) {
    return (
            <RegisterMain setToken={setToken} />
    )
}

export default Register;