import React, {useState, useEffect} from 'react';
import { CardContent, Grid, Box, Stack, setRef } from "@mui/material";
import { ButtonWrapper, CardStyled, CardTitle, CardTitleb, CardSubtitleb, FormLabel, LinearProgressStyled, ProgressBarWrapper, ProgressLabel } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import { baseURL } from '../../config/config';

async function changeTaskStatus(token, taskID, status) {
	const auth_header = "Bearer " + token;
	const toSubmit = {
		taskID: taskID
	};

	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
		body: JSON.stringify(toSubmit)
	};
    
    var response;
    if (status === "Complete") {
        response = await fetch(baseURL + "/feedback/uncompletetask", request);
    } else {
        response = await fetch(baseURL + "/feedback/completetask", request);
    }
}

async function getPlan(params) {
	const auth_header = "Bearer " + params.loginToken;
	const request = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
	};
	var response = await fetch(baseURL + "/feedback/myplan", request)
	return response.json();
}

async function deleteMilestone(token, milestoneID) {
	const auth_header = "Bearer " + token;
	const request = {
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
        body: JSON.stringify({milestoneID: milestoneID})
	};
	var response = await fetch(baseURL + "/feedback/milestone", request)
	return response.json();
}

const MilestoneFull = ({ milestoneID, tasks, token }) => {
    const [tasksst, setTasksst] = useState(tasks);
    const [completeTasks, setCompleteTasks] = useState(0);
    const [title, setTitle] = useState("[No Title]");

    useEffect(() => {
        getData();
    }, []);

    var getData = async () => {
        const res = await getPlan({ loginToken: token });
        if (res.responseCode === 200) {
            const theseTasks = [];
            for (let taskindex in res.data) {
                if (tasks[taskindex].milestoneid === milestoneID) { // && tasks[taskindex].deadlinedata !== null
                    setTitle(tasks[taskindex].milestonename);
                    theseTasks.push(tasks[taskindex]);
                }
            }
            setTasksst(theseTasks);
            setCompleteTasks(theseTasks.filter(task => task.taskstatus === "Complete").length);
        }
    };

    const HomeMilestone = (props) => {
        return (
            <CardStyled variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CardTitle>{title ? title : "[No title]"}</CardTitle>
                                <ProgressBarWrapper container>
                                    <Grid item xs={8}>
                                        <LinearProgressStyled variant="determinate" color="secondary" value={100*(completeTasks/tasksst.length)} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        { (tasksst[0].deadlinedata !== null) ?
                                            ( (100*(completeTasks/tasksst.length) === 100)
                                            ? (<ProgressLabel>Completed!</ProgressLabel>)
                                            : (<ProgressLabel>{completeTasks}/{tasksst.length} tasks</ProgressLabel>) )
                                            : (<ProgressLabel>No Tasks added</ProgressLabel>) }
                                    </Grid>
                                </ProgressBarWrapper>
                            </Grid>
                        </Grid>
                        {tasksst.map(task => (task.deadlinedata !== null) ? (
                            <Box display="flex" flexDirection="column" alignItems="center" key={task.taskid}
                                sx={{ border: 1, borderColor: '#000000', backgroundColor: '#E3F2FD',
                                borderRadius: 3, padding: 2, margin: 1, boxShadow: 3 }}
                            >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box display="flex" flexDirection="column" alignItems="center" sx={{ borderRadius: 3, padding: 2, margin: 1, backgroundColor: "#FFFFFF", boxShadow: 1 }} >
                                            <FormLabel>{task.taskname}</FormLabel>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} xl={6}>
                                        <Box display="flex" flexDirection="column" sx={{ borderRadius: 3, padding: 2 }} >
                                            <CardTitleb>Deadline:</CardTitleb>
                                            <CardSubtitleb>{task.deadlinedata}</CardSubtitleb>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} xl={6}>
                                        <Box display="flex" flexDirection="column" sx={{ borderRadius: 3, padding: 2 }} >
                                            <CardTitleb>Description:</CardTitleb>
                                            <CardSubtitleb>{task.description}</CardSubtitleb>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} xl={6}>
                                        <Box display="flex" flexDirection="column" sx={{ borderRadius: 3, padding: 2 }} >
                                            <CardTitleb>Support:</CardTitleb>
                                            <CardSubtitleb>{task.support}</CardSubtitleb>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} xl={6}>
                                        <Box display="flex" flexDirection="column" sx={{ borderRadius: 3, padding: 2 }} >
                                            <CardTitleb>Status:</CardTitleb>
                                            <CardSubtitleb>{task.taskstatus}</CardSubtitleb>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Box display="flex" flexDirection="column" alignItems="center" sx={{ borderRadius: 3, margin: 1, backgroundColor: "#FFFFFF", boxShadow: 1 }} >
                                    <SharedButton buttonType="Action" width="Long" onClick={async () => { 
                                        await changeTaskStatus(token, task.taskid, task.taskstatus);
                                        window.location.reload(true);
                                    }}>
                                        {(task.taskstatus === "Complete") ? "Mark Incomplete" : "Mark Complete"}
                                    </SharedButton>
                                </Box>
                            </Box>
                        ) : (<></>))}
                        <Box display="flex" flexDirection="column" alignItems="center" sx={{ borderRadius: 3, padding: 2, margin: 1 }} >
                            <SharedButton buttonType="Action" width="Long" onClick={async () => {
                                await deleteMilestone( token, tasksst[0].milestoneid );
                                window.location.reload(true);
                            }}>
                                Delete Milestone
                            </SharedButton>
                        </Box>
                    </CardContent>
            </CardStyled>
        )
    }

    return (
        <HomeMilestone />
    )
}

export default MilestoneFull;