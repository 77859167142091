import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { baseURL } from '../../config/config';
import { Button, Card, CardActionArea, CardContent, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import Color from "../../assets/colors";
import Milestone from "./Milestone";
import { PlanOfActionWidget, Title } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { FieldWrapper,
FormLabel,
HeaderOne,
HeaderTwo,
HeaderThree,
CreateMeetingWrapper,
SelectWrapper } from "./styles";

async function getPlan(params) {
	const auth_header = "Bearer " + params.loginToken;
	const request = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
	};

	var response = await fetch(baseURL + "/feedback/myplan", request)
	return response.json();
}

async function submitMilestone(params) {
	const auth_header = "Bearer " + params.loginToken;
	const toSubmit = {
		milestoneID: parseInt(params.milestoneValues.milestoneID),
		name: params.milestoneValues.name,
		deadlineData: params.milestoneValues.deadlineData,
		description: params.milestoneValues.description,
		support: params.milestoneValues.support
	};

	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
		body: JSON.stringify(toSubmit)
	};

	var response = await fetch(baseURL + "/feedback/task", request)
	return response.json();
}

const defaultValues = {
	milestoneID: "",
    name: "",
	deadlineData: "",
	description: "",
	support: "",
    submitText: "Submit"
};

const AddTask = ({ token }) => {
	const [values, setValues] = useState(defaultValues);
	const [milestoneOptions, setMilestoneOptions] = useState([]);


	useEffect(() => {
        getData();
    }, []);

    var getData = async () => {
        const res = await getPlan({ loginToken: token });
        if (res.responseCode === 200) {
			var mopts = [];
			var mids = []
			res.data.forEach(task => {
				if (mids.indexOf(task.milestoneid) === -1) {
					mopts.push({
						id: task.milestoneid,
						name: task.milestonename
					});
				}
				mids.push(task.milestoneid);
			})
            setMilestoneOptions([...new Set(mopts)]);
        }
    };

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setValues({
            ...values,
            [name]: value
        });
	};

	var handleSubmit = async (e) => {
		e.preventDefault();

		const res = await submitMilestone({ loginToken: token, milestoneValues: values });
		if (res.responseCode === 200) {
			setValues({...values, submitText: "Saved 😁"});
		} else {
			setValues({...values, submitText: "Error 😞"});
		}
	}

	return (
		<>
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<CreateMeetingWrapper container>
				<Grid>
					<Grid>
						<HeaderOne>Add a New Task</HeaderOne>
					</Grid>
					<Grid>
						<Grid>

							<FieldWrapper>				
								<FormLabel>Milestone</FormLabel>
								<TextField 
									id="milestoneID"
									name="milestoneID"
									type="text"
									value={values.milestoneID}
									onChange={handleInputChange}
									SelectProps={{
										native: true,
									}}							
									fullWidth
									required
									select
								>
									<option value="" />
									{milestoneOptions.map(option => (
										<option key={option.id} value={option.id}>
											{option.name}
										</option>
									))}
								</TextField>
							</FieldWrapper>

							<FieldWrapper>				
								<FormLabel>Task name</FormLabel>
								<TextField 
									id="name"
									name="name"
									type="text"
									value={values.name}
									onChange={handleInputChange}
									fullWidth
									required
								/>
							</FieldWrapper>

							<FieldWrapper>
								<FormLabel>Task Deadline</FormLabel>
								<DatePicker
									// label="Basic example"
									value={values.deadlineData}
									onChange={(date) => setValues({...values, deadlineData: date})}
									renderInput={(params) => <TextField {...params} fullWidth required />}
								/>
							</FieldWrapper>

							<FieldWrapper>				
								<FormLabel>Task description</FormLabel>
								<TextField 
									id="description"
									name="description"
									type="text"
									value={values.description}
									onChange={handleInputChange}
									multiline
									rows={4}
									fullWidth
									required
								/>
							</FieldWrapper>

							<FieldWrapper>				
								<FormLabel>Supporting information</FormLabel>
								<TextField 
									id="support"
									name="support"
									type="text"
									value={values.support}
									onChange={handleInputChange}
									multiline
									rows={4}
									fullWidth
									required
								/>
							</FieldWrapper>

							<Button
								onClick={handleSubmit}
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								{values.submitText}
							</Button>

						</Grid>
					</Grid>
				</Grid>

			</CreateMeetingWrapper>
		</LocalizationProvider>
		</>
	)
}

export default AddTask;