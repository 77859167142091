import React from 'react';
import Creategroupmeeting from '../../components/creategroupmeeting/Creategroupmeeting';
import Login from '../../components/login/Login';
import SharedButton from '../../components/shared/SharedButton/SharedButton';
import { Title, TitleWrapper } from './styles';
import { Grid } from '@mui/material';
import SharedDrawerMentor from "../../components/shared/SharedDrawerMentor/SharedDrawerMentor";

function CreateGroupMeeting({ setToken, token }) {
    return token ? (
        <>
            <SharedDrawerMentor token={token} />
            <Grid container padding={"0 4vw"}>
                <TitleWrapper container>
                    <Grid item xs={8}>
                        <Title>Organise a Group Session</Title>
                    </Grid>
                </TitleWrapper>
                <Creategroupmeeting token={token} mentor={false}></Creategroupmeeting>
            </Grid>
        </>
    ) : (
        <Login setToken={setToken} />
    );
}

export default CreateGroupMeeting;
