import { Grid } from "@mui/material";
import styled from "styled-components";

export const MenteeListWrapper = styled(Grid)`
    && {
        width: 100%;
        padding: 10px 35px;
        border-radius: 10px;
        margin: 20px;
    }
`