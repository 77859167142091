export const convertDate = function (date) {
    let d = new window.Date(date);
    let dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    
    let cDate = d.toLocaleDateString('default', dateOptions);
    let cTime = d.toLocaleTimeString('default', { hour12: true, hour: '2-digit', minute: '2-digit' });
    // console.log(cTime)
    // let day = d.getDate().toString().padStart(2, '0');
    // let month = (d.getMonth() + 1).toString().padStart(2, '0');
    // let year = d.getFullYear().toString().slice(-2);
    // let hour = d.getHours().toString().padStart(2, '0');
    // let minute = d.getMinutes().toString().padStart(2, '0');

    // console.log(d.getDate())
    let string = `${cTime}  |  ${cDate}`;
    // console.log(string)
    return string;
};

export const truncate = (str, n) => {
    if (str.length <= n) { return str; }
    const subString = str.substr(0, n-1);
    return subString.substr(0, subString.lastIndexOf(" ")) + "...";
};