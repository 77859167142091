import { Grid } from '@mui/material';
import React from 'react';
import MentorAllMeetings from '../../components/meetings/mentorAllMeetings';
import SharedDrawerMentor from '../../components/shared/SharedDrawerMentor/SharedDrawerMentor';
import { Title, TitleWrapper } from './styles';
import Login from '../../components/login/Login';

function Meetings({ setToken, token }) {
    return token ? (
        <>
            <SharedDrawerMentor token={token} />
            <Grid container padding={"0 4vw"}>
                <TitleWrapper container>
                    <Grid item xs={12}>
                        <Title>My Meetings</Title>
                    </Grid>
                </TitleWrapper>
                <MentorAllMeetings token={token} />
            </Grid>
        </>
    ) : (
        <Login setToken={setToken} />
    )
}

export default Meetings;