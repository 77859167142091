import { Grid, Typography } from "@mui/material"
import Color from "../../../assets/colors";
import styled from "styled-components";
import { FontSize } from "../../../assets/font";
import { Close } from "@mui/icons-material";

export const ErrorDialogContainer = styled(Grid)`
    && {
        background-color: ${(props) => props.$error === false ? Color.green100 : Color.red100};
        border-radius: 5px;
        padding: 10px 20px;
        display: ${(props) => props.$show ? "block" : "none"};
        width: 100%;
    }
`;

export const IconContainer = styled(Grid)`
    && {
        color: ${Color.white};
        position: relative;
        left: 98%;
        width: 20px;
        margin-bottom: -15px;

        :hover {
            cursor: pointer;
        }
    }
`

export const ErrorText = styled(Typography)`
    && {
        font-size: ${FontSize.caption};
        color: ${Color.white};
    }
`;

export const CloseIconStyled = styled(Close)`
    && {
        height: 15px;
    }
`