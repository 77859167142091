import { MenuItem, Slider, TextField, Select, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { baseURL } from "../../../config/config";
import {
    RegistrationContainer,
    TitleContainer,
    InputContainer,
    Title,
    Label,
    SubLabel,
    SkillRatingContainer,
    Caption
} from "./styles";

// Call API to get topics
async function getTopics(token) {
    const auth_header = "Bearer " + "187c0b81eb55a5a7dc8cb59a2278efe7fd2c2513a278c6fd4bf3fd322af1c9c1";
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    }

    return fetch(baseURL + "/topics", request)
        .then((res) => res.json());
}

function MenteeProfile(props) {
    const {
        handleChange,
        handleMetadataChange,
        handleTopicsChange,
        values,
        errors,
        cToken
    } = props;

    const {
        topics,
        proficiencies,
        eduStyle
    } = values;

    const {
        topicsError,
        eduStyleError,
    } = errors;

    useEffect(() => {
        getData(cToken);
    }, []);

    const [cTopics, setcTopics] = useState({});
    // const [proficiencies, setProficiencies] = useState({});

    const getData = async (cToken) => {
        const topics = await getTopics(cToken)
            .then((res) => {
                if (res.responseCode === 200) {
                    setcTopics(res.data);
                }
            })
    }

    const marks = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 2,
            label: '2',
        },
        {
            value: 3,
            label: '3',
        },
        {
            value: 4,
            label: '4',
        },
        {
            value: 5,
            label: '5',
        }
    ]

    // To have skill ratings for each topic
    const skillRatings = Object.keys(cTopics).map((key) => {
        return (
            <SkillRatingContainer>
                <SubLabel>How would you rate your skills in {cTopics[key]}?</SubLabel>
                <Rating
                    aria-label={cTopics[key]}
                    name={key}
                    value={proficiencies[key] ?? ""}
                    onChange={handleMetadataChange("proficiency")}
                    key={key}
                />
            </SkillRatingContainer>
        )
    }
    );

    const areas = Object.keys(cTopics).map((key) => {
        return (
            <MenuItem value={key}>{cTopics[key]}</MenuItem>
        )
    }
    );

    return (
        <RegistrationContainer>
            <TitleContainer>
                <Title>Mentee Profile</Title>
            </TitleContainer>
            <InputContainer>
                <Label>Area of Interest</Label>
                <Caption>The area you wish to be mentored in.</Caption>
                <Select
                    label="Area of Interest"
                    id="area-of-interest"
                    error={topicsError}
                    value={topics}
                    onChange={handleTopicsChange}
                    helperText={topicsError ? "Please choose the area you are interested in being mentored in" : ""}
                    fullWidth
                    required
                    select
                >
                    {areas}
                </Select>
            </InputContainer>
            <InputContainer>
                <Label>What is your learning style?</Label>
                <TextField
                    label="Learning Style"
                    id="edu-style"
                    error={eduStyleError}
                    value={eduStyle}
                    onChange={handleMetadataChange("eduStyle")}
                    helperText={eduStyleError ? "Please choose your preferred learning style" : ""}
                    fullWidth
                    required
                    select
                >
                    <MenuItem value="Visual">I am a visual learner</MenuItem>
                    <MenuItem value="Auditory">I am an auditory learner</MenuItem>
                    <MenuItem value="Kinesthetic">I am a kinesthetic learner</MenuItem>
                </TextField>
            </InputContainer>
            <InputContainer>
                <Label>Proficiencies</Label>
                {skillRatings}
            </InputContainer>
        </RegistrationContainer>

    )
}

export default MenteeProfile;