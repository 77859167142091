import {
    Card,
    CardContent,
    CardMedia,
    Grid,
    Typography
} from "@mui/material";
import styled, { css } from 'styled-components';
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";

// Grid
export const CardWrapper = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
    }
`;

export const CardsContainer = styled(Grid)`
    && {
        margin: 20px 0;
        display: flex;
        justify-content: center;
        width: 100%;
    }
`

export const MentorOrMenteeWrapper = styled(Grid)`
    && {
        margin: 15vh auto 8vh;
        width: 45%;
        padding: 0 auto;
        min-width: 850px;
    }
`;

export const TitleWrapper = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        width: 100%;
    } 
`;

export const CardMediaWrapper = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        margin: 30px 30px 0;
    }
`

// Typography
export const CardTitle = styled(Typography)`
    && {
        font-size: ${FontSize.header3};
        font-weight: 700;
        color: ${Color.sapphire100};
        text-transform: uppercase;
    }
`;

export const Title = styled(Typography)`
    && {
        font-size: ${FontSize.header2};
        font-weight: 700;
        text-align: center;
    }
`;

// Card
export const CardStyled = styled(Card)`
    && {
        background-color: ${(props) =>
            props.selected ? `${Color.green100}` : `${Color.green80}`};
        border-radius: 50px;
        width: 310px;
        height: 360px;

        ${(props) =>
        props.selected &&
        css`
            border-style: solid;
            border-color: ${Color.black};
      `}
    }
`;

export const CardContentStyled = styled(CardContent)`
    && {
        justify-content: center;
        display: flex;
    }
`;

export const CardMediaStyled = styled(CardMedia)`
    && {
        border-radius: 50px;
        width: 244px;
        height: 240px;
    }
`;
