import React, { useState, useEffect } from 'react';
import { Avatar, Button, Card, CardActionArea, CardContent, Divider, Grid, Typography } from "@mui/material";
import Color from "../../assets/colors";
import Notification from "./Notification";
import { MyNotificationsWidget, Title, Subtitle } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import { baseURL } from '../../config/config';
import { RefreshRounded } from '@mui/icons-material';

async function getNotifications(token) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

    var response = await fetch(baseURL + "/profile/notifications", request);
    var json = await response.json() ?? {};

    if (json.responseCode !== 200) return [];
    return json.data;
}

async function read(token, id, refresh) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

    var response = await fetch(baseURL + "/profile/notifications/dismiss/" + id, request);
    var json = await response.json() ?? {};

    if (json.responseCode === 200) {
        refresh();
    }
}

function NotificationList({ token }) {

    const [notifications, setNotifications] = useState([]);

    let refresh = async () => {
        let mentees = await getNotifications(token);
        if (mentees != null) setNotifications(mentees);
    };

    useEffect(() => {
        refresh();
    }, []);

    return (
        <MyNotificationsWidget minWidth={355}>
            <Grid>
                <Title>Notifications</Title>
            </Grid>
            {notifications.length === 0 && <Subtitle>No notifications.</Subtitle>}
            <Grid container>
                {notifications.map((noti) => {
                    return (<Notification title={noti.sendername} subtitle={noti.messagecontent} profile={noti.senderpicture} update={() => { read(token, noti.notificationid, refresh) }} key={noti.notificationid} />)
                })}
            </Grid>
        </MyNotificationsWidget>
    )
}

export default NotificationList;