import React, {useState, useEffect} from 'react';
import { Grid } from "@mui/material";
import Mentee from "./Mentee";
import { MyMenteesWidget, Title } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import { baseURL } from '../../config/config';
import { Subtitle } from '../groupsessions/widgets/GroupSessionList/styles';
import { useNavigate } from 'react-router-dom';

async function getMentees(token) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

    var response = await fetch(baseURL + "/matching/mymentees", request);
    var json = await response.json() ?? {};

    if (json.responseCode !== 200) return [];
    return json.data;
}

function MenteeList({ token }) {
    const navigate = useNavigate();
    const [mentees, setMentees] = useState([]);

    useEffect(() => {
        (async () => {
            let mentees = await getMentees(token);
            if (mentees != null) setMentees(mentees);
        })();
    }, []);

    return (
        <MyMenteesWidget minWidth={355}>
            <Grid container spacing={6}>
                <Grid item xs>
                    <Title>My Mentees</Title>
                </Grid>
                <Grid item xs="auto">
                    <SharedButton buttonType="Action" width="Short" onClick={() => navigate("/mentor/my_mentees")}>View All</SharedButton>
                </Grid>
            </Grid>
            <Grid container>
                {mentees.map((mentee) => {     
                    return (
                        <Mentee 
                            id={mentee.userid}
                            name={mentee.firstname + " " + mentee.secondname}
                            area={mentee.topicname}
                            profile={mentee.profilepicture}
                            key={mentee.userid}
                        />
                    ) 
                })}
                {mentees.length === 0 &&
                (<Subtitle>You have no mentees.</Subtitle>)}
            </Grid>
        </MyMenteesWidget>
    )
}

export default MenteeList;