import { 
    Grid, 
    Typography 
} from "@mui/material";
import styled from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";

// Grid
export const StepperContainer = styled(Grid)`
  && {
    margin: 5px 0;
    display: flex;
    justify-content: center;
  }
`;

export const StepNumbersContainer = styled(Grid)`
  && {
    position: absolute;
    width: 45%;
    min-width: 850px;
    top: 10%;
    height: 5px;
    border-radius: 20px;
    background-color: ${Color.sapphire100};
    margin: auto;
  }
`;

export const StepContainer = styled(Grid)`
  && {
    position: absolute;
    z-index: 2;
    border-radius: 200px;
    transform: translateY(-40px);
    width: 80px;
    height: 80px;
    background: ${(props) =>
        props.activeStep == props.step ? 
            `${Color.green100}` : `${Color.sapphire100}`};

    ${(props) =>
        props.step == "1" && {
            left: "5%"
        }}

    ${(props) =>
        props.step == "2" && {
            left: "45%"
        }}

    ${(props) =>
        props.step == "3" && {
            left: "85%"
        }}
  }
`;

export const StepNumberWrapper = styled(Grid)`
  && {
    position: relative;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex: 1;
    height: 100%;
    transform: translateY(5px);
    color: ${(props) =>
        props.activeStep == props.step ? `${Color.white}` : `${Color.black}`};
  }
`;

export const ButtonWrapper = styled(Grid)`
    && {
        width: 60%;
        padding-bottom: 30px;
        margin: 0 auto;
        min-width: 850px;
    }
`;

export const BackButton = styled(Grid)`
    && {
        display: flex;
        justify-content: flex-start;
    }
`;

export const NextButton = styled(Grid)`
    && {

        display: flex;
        justify-content: flex-end;
    }
`;


// Typography
export const StepNumber = styled(Typography)`
    && {
        font-size: ${FontSize.header2};
        font-weight: 700;
        color: ${Color.white};
    }
`;

// Misc.
export const RegistrationContainer = styled.div`
    && {
        height: 100vh;
    }
`;