import {
    Grid,
    Typography,
    Rating
} from "@mui/material";
import Color from "../../assets/colors";
import { FontSize } from "../../assets/font";
import styled from "styled-components";

// Typography
export const Title = styled(Typography)`
    && {
        font-size: ${FontSize.header3};
        font-weight: 500;
        color: ${Color.black};
    }
`;

export const QuestionLabel = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
        width: 100%;
        text-align: center;
        margin: 15px 0;
    }
`;

// Grid
export const FeedbackContainer = styled(Grid)`
    && {
        margin: 80px;
    }
`;

export const ButtonWrapper = styled(Grid)`
    && {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 10px 0;
    }
`;

export const RatingContainer = styled(Grid)`
    && {
        display: flex;
        justify-content: center;
        margin: 20px 5px;
    }
`;

export const CommentsContainer = styled(Grid)`
    && {
        margin: 20px 5px;
    }
`;

// Misc.
export const RatingStyled = styled(Rating)`
    && {
        font-size: 50px;
    }
`;