import styled from 'styled-components';
import { FontSize } from '../../assets/font';
import { 
    Typography,
    Grid,
 } from '@mui/material';
import Color from '../../assets/colors';

// Typography
export const Title = styled(Typography)`
    && {
        font-size: ${FontSize.header3};
        font-weight: 500;
    }
`;

export const StatusText = styled(Typography)`
    && {
        font-size: ${FontSize.body};
        color: ${(props) => props.status == "Confirmed" ? Color.white : Color.black};
        font-weight: 500;
        text-align: center;
    }`

// Grid
export const TitleWrapper = styled(Grid)`
    && {
        margin: 20px 30px;
    }
`

export const StatusWrapper = styled(Grid)`
    && {
        background-color: ${(props) => props.status == "Confirmed" ? Color.green100 : Color.white};
        border-radius: 10px;
        padding: 5px 10px;
        max-width: 200px;
    }
`;