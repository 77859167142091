import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { FontSize } from "../../assets/font";

// Grid
export const ChooseMentorContainer = styled(Grid)`
    && {
        margin: 120px;
    }
`;

export const TitleContainer = styled(Grid)`
    && {
        margin: 25px 0;
    }
`;

export const FilterContainer = styled(Grid)`
    && {
        margin: 25px 0;
    }
`;

export const ButtonWrapper = styled(Grid)`
    && {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
`;

export const ListContainer = styled(Grid)`
    && {

    }
`;

// Typography
export const Title = styled(Typography)`
    && {
        font-size: ${FontSize.header3};
        weight: 500;
    }
`;

export const Subheading = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
        margin: 5px 0;
        weight: 400
    }
`