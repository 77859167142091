import React from 'react';
import SharedButton from "../shared/SharedButton/SharedButton";

import {
    ButtonWrapper,
    MyMentorWidget,
    Subtitle,
    Mentor,
    TextWrapper,
    Title
} from "./styles";
import {
    Link, useNavigate
} from "react-router-dom";
import { Box } from '@mui/system';
import { Grid } from '@mui/material';

function MyMentor({ mentor }) {

    const navigate = useNavigate();

    return (
        <MyMentorWidget minWidth={355} container>
            <Grid item xs={12}>
                <Title>Current Mentorship</Title>
                <Subtitle>{"Mentoring topic: " + (mentor ? mentor.topicname : "")}</Subtitle>
                <Mentor>{"Mentor: " + (mentor ? mentor.firstname + " " + mentor.secondname : "")}</Mentor>
            </Grid>
            {mentor != null &&
                <Box>
                    <ButtonWrapper item xs="auto">
                        <SharedButton buttonType="Action" width="Long" onClick={() => navigate("/mentee/createmeeting")}>Book a Meeting</SharedButton>
                    </ButtonWrapper>
                    <ButtonWrapper item xs="auto">
                        <SharedButton buttonType="Action" width="Short" onClick={() => {
                            navigate(`/users/mentors/${mentor.userid}`);
                    }}>View</SharedButton>
                    </ButtonWrapper>
                </Box>
            }
            {mentor == null &&
                <ButtonWrapper item xs="auto">
                    <SharedButton buttonType="Action" width="Long" onClick={() => {
                        navigate("/mentee/choosementor");
                    }}>Find Mentor</SharedButton>
                </ButtonWrapper>
            }
        </MyMentorWidget>
    )
}

export default MyMentor;