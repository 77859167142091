import React, { useEffect, useState, Component  } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import DetailedMeeting from "./DetailedMeeting";
import { Date, DateSessions, MyMeetingList, Title } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import SharedDate from "../shared/SharedDate/SharedDate";
import { baseURL } from '../../config/config';
import { convertDate } from '../../util';

async function allMeetings(token) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

	var response = await fetch(baseURL + "/event/mentor/mysessions", request);
    var json = await response.json() ?? {};
    
    if (json.responseCode !== 200) return [];
    return json.data;
}

function MenteeAllMeetings({ token }) {
    
    const [meetingsRequest, setMeetingsRequest] = useState([]);
    
    let refresh = (async () => {
        let meetingsRequest = await allMeetings(token);
        if (meetingsRequest != null) setMeetingsRequest(meetingsRequest);
    });

    useEffect(() => {
        refresh();
    }, []);

    let meetingDisplay = [meetingsRequest.length];
    if (meetingsRequest.length) {
        let i = 0;
        meetingsRequest.map((meeting) => {
            if (meeting.type === "Group" || meeting.type === "Workshop") {
                meetingDisplay[i] = <DetailedMeeting
                    title={meeting.description}
                    token={token}
                    type={meeting.type === "Workshop" ? meeting.type : meeting.type + " Meeting"}
                    name={meeting.currentparticipants + " participants"}
                    date={meeting.proposeddate}
                    venue={meeting.location}
                    status={meeting.status}
                    mentorid={meeting.mentorid}
                    key={meeting.sessionid}
                    id={meeting.sessionid}
                    mode="mentor"
                    update={refresh}
                    participants={meeting.participants}
                    isparticipant={meeting.userIsParticipant}
                    finishdate={meeting.finishdate}  />
            } else {
                meetingDisplay[i] = <DetailedMeeting
                    title={meeting.description}
                    token={token}
                    type={meeting.type}
                    name={meeting.menteefname + " " + meeting.menteesname}
                    date={meeting.proposeddate}
                    venue={meeting.location}
                    status={meeting.status}
                    mentorid={meeting.mentorid}
                    key={meeting.sessionid}
                    id={meeting.sessionid}
                    mode="mentor"
                    update={refresh}
                    participants={meeting.participants}
                    isparticipant={meeting.userIsParticipant}
                    finishdate={meeting.finishdate}   />
            }
            i++;
        })
    } else {
        meetingDisplay = (<Typography>You have no past or future meetings as a mentor.</Typography>)
    }

    return (
        <MyMeetingList>
            <DateSessions container>
                <Divider />
                {meetingDisplay}
            </DateSessions>
        </MyMeetingList>
    )
}

export default MenteeAllMeetings;