import styled from 'styled-components';
import { FontSize } from '../../assets/font';
import { 
    Typography,
    Grid,
    Link,
    Button
 } from '@mui/material';
import Color from '../../assets/colors';

// Typography
export const Title = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
        color: ${Color.white};
        margin: 5px 0;
    }
`;

export const Subtitle = styled(Typography)`
    && {
        font-size: ${FontSize.subheading};
        color: ${Color.white};
        margin: 8px 0;
        font-weight: 600;
    }
`;

// Link
export const Mentor = styled(Link)`
    && {
        font-family: Work Sans !important;
        font-size: ${FontSize.subheading};
        color: ${Color.white};
        margin: 8px 0;
        font-weight: 600;
        text-decoration: none;
    }
`;

// Grid
export const MyMentorWidget = styled(Grid)`
    && {
        background-color: ${Color.sapphire100};
        width: 45%;
        padding: 30px 35px;
        border: 1px solid ${Color.grey100};
        border-radius: 10px;
        margin: 40px;
    }
`;

export const TextWrapper = styled(Grid)`
    && {
        margin: 15px 30px;
    }
`;

export const ButtonWrapper = styled(Grid)`
    && {
        margin: 20px 2px 5px;
    }
`;

// Button
export const ButtonStyled = styled(Button)`
    && {
        width: 270px;
        height: 46px;
        padding: 10px 40px;
        background-color: ${Color.blue100};
        color: ${Color.white};
        text-transform: uppercase;
        font-size: ${FontSize.body};
        font-weight: 500;
    }
`