import React from "react";
import { Avatar, CardContent, Grid, CardActionArea } from "@mui/material";
import { AvatarContainer, ButtonWrapper, CardStyled, CardSubtitle, CardTextContainer, CardTitle } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import { useNavigate } from "react-router-dom";

function Mentee(props) {
    const navigate = useNavigate();
    const { id, name, area, profile } = props;

    return (
        <CardStyled variant="outlined">
            <CardContent>
                <Grid container spacing={2}>
                    <AvatarContainer item xs="auto">
                        <Avatar sx={{ minWidth: "50px", minHeight: "50px", width: "5vw", height: "5vw" }} alt="Profile picture" src={profile}></Avatar>
                    </AvatarContainer>
                    <CardTextContainer item xs>
                        <CardTitle>{name ? name : "Name"}</CardTitle>
                        <CardSubtitle>{area ? area : "Area of Interest"}</CardSubtitle>
                    </CardTextContainer>
                    <ButtonWrapper item xs="auto">
                        <SharedButton buttonType="Action" width="Short" onClick={() => {
                            navigate(`/users/mentees/${id}`)
                        }}>View</SharedButton>
                    </ButtonWrapper>
                </Grid>
            </CardContent>
        </CardStyled>
    )
}

export default Mentee;