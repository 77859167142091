import styled from 'styled-components';
import { FontSize } from '../../assets/font';
import { Grid, Link, Typography } from '@mui/material';
import Color from '../../assets/colors';
import { Box } from '@mui/system';
import background from '../../static/images/bg.png';

// Typography
export const HeaderOne = styled(Typography)`
    && {
        font-size: ${FontSize.header1};
        font-weight: 700;
    }
`;

export const FormLabel = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
    }
`;

export const Caption = styled(Typography)`
    && {
        font-size: ${FontSize.body};
    
    }
`;

export const ErrorText = styled(Typography)`
    && {
        font-size: ${FontSize.body};
        color: ${Color.red100};
        display: ${(props) =>
            props.show ? "block" : "none"};

    }
`

// Grid
export const LogInWrapper = styled(Grid)`
    && {
        height: 100vh;
        margin: 0;
        padding: 0;
    }
`;

export const FormWrapper = styled(Grid)`
    && {
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 15vh 20px;
    }
`;

export const FieldWrapper = styled(Grid)`
    && {
        margin: 8px 0;
    }
`;

export const ButtonWrapper = styled(Grid)`
    && {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 10px 0;
    }
`;

export const RegisterWrapper = styled(Grid)`
    && {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const ForgotPasswordWrapper = styled(Grid)`
    && {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const LinksWrapper = styled(Grid)`
    && {
        margin: 25px 0;
    }
`

// Box
//        background-color: ${Color.sapphire100};
export const BoxStyled = styled(Box)`
    && {
        background-image: url(${background});
        height: 100%;
    }
`;

// Link
export const LinkStyled = styled(Link)`
    && {
        font-size: ${FontSize.body};
        color: ${Color.blue100};
        margin: 0 10px;
    }
`