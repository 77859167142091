import styled from 'styled-components';
import { FontSize } from '../../../../assets/font';
import { 
    Typography,
    Grid,
    Card,
 } from '@mui/material';
import Color from '../../../../assets/colors';

// Typography
export const Title = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
        color: ${Color.black};
    }
`;

export const Subtitle = styled(Typography)`
    && {
        font-size: ${FontSize.subheading};
        margin: 8px 0;
        font-weight: 600;
    }
`;

export const BodyText = styled(Typography)`
    && {
        font-size: ${FontSize.subheading};
        margin: 8px 0;
        font-weight: 400;
    }
`;

// Grid
export const CreateGroupSessionContainer = styled(Grid)`
    && {
        width: 45%;
        padding: 35px 45px;
        border: 1px solid ${Color.grey100};
        border-radius: 10px;
        margin: 40px;
    }
`;

export const ButtonWrapper = styled(Grid)`
    && {
        margin: 20px 2px 5px;
    }
    }
`;

export const TextWrapper = styled(Grid)`
    && {
        margin: 15px 30px;
    }
`;