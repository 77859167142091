import React from "react";
import { CardContent, Grid } from "@mui/material";
import { ButtonWrapper, CardStyled, CardSubtitle, CardTitle } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import { useNavigate } from 'react-router-dom';
import { convertDate, truncate } from "../../util";

function Meeting(props) {
    const navigate = useNavigate();

    const { title, date, venue, mode } = props;

    const MyMeetingsHome = () => {
        return (
            <CardStyled variant="outlined">
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <CardTitle>{title ? truncate(title, 65) : "Meeting Title"}</CardTitle>
                            <CardSubtitle>{(date ? convertDate(date) : "Time") + ", " + (venue ? venue : "Venue")}</CardSubtitle>
                        </Grid>
                        <Grid item>
                            <ButtonWrapper item>
                                <SharedButton buttonType="Action" width="Short" onClick={() => {
                                    navigate('/session', { state: { session: props, mode } });
                                }}>View</SharedButton>
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardStyled>
        )
    }

    return (
        <MyMeetingsHome />
    )
}

export default Meeting;