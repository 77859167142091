import React, { useEffect, useState } from "react";
import { Button, Card, CardActionArea, CardContent, Divider, Grid, Typography } from "@mui/material";
import SharedButton from "../../../shared/SharedButton/SharedButton";
import { ButtonWrapper, CardStyled, CardSubtitle, CardTitle, Date, DateSessions, GroupSessionsWidget, Title } from "./styles";
import { baseURL } from '../../../../config/config';
import { convertDate } from '../../../../util';
import { useNavigate } from "react-router-dom";
import DetailedMeeting from "../../../meetings/DetailedMeeting";

async function groupSessions(token) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

	var response = await fetch(baseURL + "/event/homepagegroupsessions", request);
    var json = await response.json() ?? {};
    
    if (json.responseCode !== 200) return [];
    return json.data;
}

function GroupSession({ token }) {

    const navigate = useNavigate();
    const [meetingsRequest, setMeetingsRequest] = useState([]);
    
    let refresh = (async () => {
        let meetingsRequest = await groupSessions(token);
        if (meetingsRequest != null) setMeetingsRequest(meetingsRequest);
    });

    useEffect(() => {
        refresh();
    }, []);

    let meetingDisplay = [meetingsRequest.length];
    if (meetingsRequest.length) {
        let i = 0;
        meetingsRequest.map((meeting) => {
            if (meeting.userIsParticipant) return;

            meetingDisplay[i] = <DetailedMeeting
            token={token}
            title={meeting.description}
            type={meeting.type}
            name={meeting.mentorfname + " " + meeting.mentorsname}
            date={meeting.proposeddate}
            venue={meeting.location}
            mentorid={meeting.mentorid}
            status={meeting.status}
            decor={meeting.userIsParticipant ? "Signed up!" : (meeting.maxparticipants - meeting.currentparticipants + " spaces left")}
            mode="mentee"
            id={meeting.sessionid}
            key={meeting.sessionid}
            update={refresh}
            participants={meeting.participants}
            isparticipant={meeting.userIsParticipant}
            finishdate={meeting.finishdate} 
            truncateTitle
            />
            i++;
        })
    } else {
        meetingDisplay = (<Typography>There are no group sessions available.</Typography>)
    }

    const [sessions, setSessions] = useState([]);

    useEffect(() => {
        (async () => {
            let res = await groupSessions(token);
            if (res != null) setSessions(res);
        })();
    }, []);

    return (
        <GroupSessionsWidget minWidth={355}>
            <Grid container spacing={6}>
                <Grid item xs>
                    <Title>Group Sessions Available</Title>
                </Grid>
                <Grid item xs="auto">
                    <SharedButton buttonType="Action" width="Short" onClick={() => navigate("/mentee/groupmeetings")}>View All</SharedButton>
                </Grid>
            </Grid>
            <DateSessions container>
                <Divider />

                {meetingDisplay}
            </DateSessions>
        </GroupSessionsWidget>
    )
}

export default GroupSession;