import styled from 'styled-components';
import { FontSize } from '../../assets/font';
import { 
    Typography,
    Grid,
    Link,
    Button,
    Card,
    Divider,
    LinearProgress,
    Avatar
 } from '@mui/material';
import Color from '../../assets/colors';

// Typography
export const Title = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
        color: ${Color.black};
    }
`;

export const CardTitle = styled(Typography)`
    && {
        font-size: ${FontSize.subheading};
        color: ${Color.white};
        font-weight: 600;
    }
`;

export const CardSubtitle = styled(Typography)`
    && {
        font-size: ${FontSize.body};
        color: ${Color.white};
        margin: 8px 0 0 0;
    }
`;

export const ProgressLabel = styled(Typography)`
    && {
        font-size: ${FontSize.body};
        font-weight: 400;
        color: ${Color.white};
        margin: 0 5px;
    }
`;

export const Date = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
        font-weight: 400;
        width: 100%;
    }
`;

// Grid
export const MyMenteesWidget = styled(Grid)`
    && {
        width: 45%;
        padding: 30px 35px;
        border: 1px solid ${Color.grey100};
        border-radius: 10px;
        margin: 40px;
    }
`;

export const DateSessions = styled(Grid)`
    && {
        padding: 0 5px;
    }
`;

export const ButtonWrapper = styled(Grid)`
    && {
        margin: 5px 2px;
        display: flex;
        align-items: center;
    }
`;

export const AvatarContainer = styled(Grid)`
    && {
        display: flex;
        align-items: center;
        margin: 0 5px;
    }
`;

export const CardTextContainer = styled(Grid)`
    && {
        margin: 0 5px;
    }
`;

export const ProgressBarWrapper = styled(Grid)`
    && {
        display: flex;
        align-items: center;
    }
`;

 // Card
 export const CardStyled = styled(Card)`
    && {
        width: 100%;
        padding: 4px 8px;
        background-color: ${Color.sapphire100};
        margin: 12px 0;
    }
`;
