import styled from 'styled-components';
import { FontSize } from '../../assets/font';
import { 
    Typography,
    Grid,
    Card,
    LinearProgress,
 } from '@mui/material';
import Color from '../../assets/colors';

// Typography
export const Title = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
        color: ${Color.black};
    }
`;

export const CardTitle = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
        color: ${Color.white};
        font-weight: 400;
    }
`;

export const CardSubtitle = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
        color: ${Color.white};
        font-weight: 400
    }
`;

export const CardText = styled(Typography)`
    && {
        font-size: ${FontSize.body};
        color: ${Color.white};

    }
`;

export const ProgressLabel = styled(Typography)`
    && {
        font-size: ${FontSize.body};
        font-weight: 400;
        color: ${Color.white};
        margin: 0 5px;
    }
`;

export const BodyText = styled(Typography)`
    && {
        font-size: ${FontSize.body};
    }
`;

export const Subtitle = styled(Typography)`
    && {
        font-size: ${FontSize.header3};
        font-weight: 500;
    }
`;

export const Subsubheading = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
        font-weight: 400;
        color: ${Color.white};
    }
`

// Grid
export const ButtonWrapper = styled(Grid)`
    && {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    }
`;

export const IconContainer = styled(Grid)`
    && {
        height: 100%;
        margin-right: 8px;
        display: flex;
        align-items: center;
    }
`;

export const MilestoneWrapper = styled(Grid)`
    && {
        
    }
`;

export const TaskWrapper = styled(Grid)`
    && {
        background-color: ${Color.white};
        margin: 10px 10px;
        padding: 15px 35px;
        border-radius: 10px;
    }
`;

export const StatusWrapper = styled(Grid)`
    && {
        background-color: ${(props) => props.status == "Complete" ? Color.green100 : Color.red80};
        border-radius: 10px;
        margin: 0 5px;
        padding: 10px;
        color: ${Color.white};
        align-text: center;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        max-height: 35px;
        
    }
`;

export const IconTextContainer = styled(Grid)`
    && {
        display: flex;
        color: ${(props) =>
        props.black ? 
            `${Color.black}` : `${Color.white}`};
        align-items: center;
    }
`;

export const AvatarContainer = styled(Grid)`
    && {
        margin: 5px 16px;
    }
`;

export const CardTextContainer = styled(Grid)`
    && {
        
        margin: 5px 16px;
    }
`;

export const UserDetails = styled(Grid)`
    && {
        background-color: ${Color.sapphire100};
        padding: 28px;
    }
`;

export const UserPageWrapper = styled(Grid)`
    && {
        margin: 90px 140px;
        min-width: 184px;
    }
`;

export const AboutWrapper = styled(Grid)`
    && {
        margin: 45px 10px;
    }
`

 // Card
 export const CardStyled = styled(Card)`
    && {
        width: 100%;
        padding: 4px 8px;
        background-color: ${Color.sapphire100};
        margin: 12px 0;
    }
`;

// Misc.
export const LinearProgressStyled = styled(LinearProgress)`
    && {
        height: 14px;
        background-colour: ${Color.green100};
        margin-right: 10px; 
    }
`;