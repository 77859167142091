import React from 'react';
import { ButtonStyled } from './styles';

const SharedButton = ({
    type,
    onClick,
    children,
    buttonType,
    margin,
    padding,
    textTransform,
    disabled,
    width
}) => {
    return (
        <ButtonStyled
            type={type}
            onClick={onClick}
            buttonType={buttonType}
            margin={margin}
            padding={padding}
            //textTransform={textTransform}
            width={width}
            disabled={disabled}
        >
            {children}
        </ButtonStyled>
    );
};

export default SharedButton;