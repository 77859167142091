import React, { useEffect, useState } from "react";
import { CardContent, Divider, Grid, TextField, Typography } from "@mui/material";
import { ButtonWrapper, CardStyled, CardSubtitle, CardTitle, StatusText, StatusWrapper } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import { Link, useNavigate } from "react-router-dom";
import BasicModal from "../modal/BasicModal";
import { convertDate, truncate } from "../../util";
import { DesktopDateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { baseURL } from "../../config/config";

async function updateMeeting(token, sessionID, update, newDate=null) {
    const auth_header = "Bearer " + token;
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
    };

    if (newDate != null) {
        request.body = JSON.stringify({proposedDate: newDate.toISOString()});
    }
  
    var response = await fetch(baseURL + "/event/" + (newDate == null ? "accept" : "invite") + "/" + sessionID, request);
    update();
    return response.json();
  }

function DetailedMeeting(props) {
    const { id, title, type, name, date, venue, status, mode, decor, token, update, mentorid, participants, isparticipant, finishdate, truncateTitle } = props;
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [mMode, setMMode] = useState(false);
    const [inputDate, setInputDate] = useState(new Date(date));

    return (
        <CardStyled variant="outlined">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid container item xs={12}>
                        <Grid item xs>
                            <CardTitle>{(type ? type : "") + " with " + (name ? name : "")}</CardTitle>
                        </Grid>
                        <Grid item justifyContent={"flex-end"} maxWidth="250px">
                            <StatusWrapper status={status}>
                                <StatusText status={status}>{decor ? decor : (status ? (status.replace("Pending", "Waiting for ")) : "")}</StatusText>
                            </StatusWrapper>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2} xs={12}>
                        <Grid item xs>
                            <CardSubtitle>{"Description: " + (title ? (truncateTitle ? truncate(title, 65) : title) : "Untitled Meeting")}</CardSubtitle>
                            <CardSubtitle>{"Date & Time: " + (date ? convertDate(date) : "")}</CardSubtitle>
                            <CardSubtitle>{"Venue: " + (venue ? venue : "")}</CardSubtitle>
                        </Grid>
                        <Grid item xs={"auto"}>
                            {((status === "PendingMentor" && mode === "mentor") || (status === "PendingMentee" && mode === "mentee")) &&
                                <div>
                                    <ButtonWrapper item>
                                        <SharedButton buttonType="Action" width="Short" onClick={() => {
                                            setMMode(false);
                                            setOpen(true);
                                        }}>Manage</SharedButton>
                                    </ButtonWrapper>
                                    <BasicModal open={open} setOpen={setOpen}>
                                        <div style={{display: mMode ? "none" : "block"}}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">Manage Meeting</Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>You can either accept the proposed time or request a different time.</Typography>
                                            <Typography marginTop="15px">Proposed time: {convertDate(date)}</Typography>
                                            <Divider></Divider>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <ButtonWrapper item>
                                                        <SharedButton buttonType="Action" width="Short" onClick={() => {
                                                            setOpen(false);
                                                            updateMeeting(token, id, update);
                                                        }}>Accept</SharedButton>
                                                    </ButtonWrapper>
                                                </Grid>
                                                <Grid item>
                                                    <ButtonWrapper item>
                                                        <SharedButton buttonType="Action" width="Long" onClick={() => {
                                                            setMMode(true);
                                                        }}>Request New Time</SharedButton>
                                                    </ButtonWrapper>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div style={{ display: mMode ? "block" : "none" }}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">Manage Meeting</Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>Please input a new proposed time:</Typography>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDateTimePicker
                                                    clearable
                                                    id="date"
                                                    value={inputDate}
                                                    onChange={(newValue) => setInputDate(newValue)}
                                                    minDateTime={new Date()}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                            <Grid container spacing={1} marginTop="10px">
                                                <Grid item>
                                                    <ButtonWrapper item>
                                                        <SharedButton buttonType="Action" width="Short" onClick={() => {
                                                            setMMode(false);
                                                        }}>Back</SharedButton>
                                                    </ButtonWrapper>
                                                </Grid>
                                                <Grid item>
                                                    <ButtonWrapper item>
                                                        <SharedButton buttonType="Action" width="Short" onClick={() => {
                                                            if (inputDate >= new Date()) {
                                                                setOpen(false);
                                                                updateMeeting(token, id, update, inputDate);
                                                            }
                                                        }}>Request</SharedButton>
                                                    </ButtonWrapper>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </BasicModal>
                                </div>
                            }
                            <ButtonWrapper item>
                                <SharedButton buttonType="Action" width="Short" onClick={() => {
                                    navigate('/session', { state: { session: { id, title, date, status, venue, mentorid, participants, isparticipant, finishdate, type }, mode } });
                                }}>View</SharedButton>
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </CardStyled>
    )
}

export default DetailedMeeting;