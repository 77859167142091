import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Rating,
    TextField
} from "@mui/material";
import SharedButton from "../shared/SharedButton/SharedButton";
import { baseURL } from "../../config/config";
import {
    ButtonWrapper, 
    FeedbackContainer, 
    QuestionLabel, 
    RatingContainer, 
    RatingStyled, 
    Title
} from "./styles";
import SharedErrorDialog from "../shared/SharedErrorDialog/SharedErrorDialog";

async function submitFeedback(token, params) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
        body: JSON.stringify(params)
    }

    return fetch(baseURL + "/feedback/site", request)
        .then(res => res.json());
}

function SiteFeedback() {
    useEffect(() => {
        const cToken = JSON.parse(localStorage.getItem('token'));

        if (!cToken) {
            window.location.href = "/login";
            return;
        }
        setToken(cToken);
    }, []);

    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState("");
    const [ctoken, setToken] = useState("");
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState("");

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    }

    const handleRatingChange = (e) => {
        setRating(e.target.value);
    }

    const onSubmitFeedback = (e) => {
        e.preventDefault();
        setShowErrorDialog(false);

        if (!rating) {
            setErrorMessage("Please provide a rating for the site.");
            setShowErrorDialog(true);
            return;
        }

        let params = {
            siterating: rating,
            comments: feedback,
        }

        submitFeedback(ctoken, params);
        setConfirmationMessage("Submitted! Thank you for your feedback!");
        setShowConfirmationDialog(true);
        setRating(0);
        setFeedback("");
    }

    const toggleConfirmationDialog = () => {
        setShowErrorDialog(!showErrorDialog);
    }

    const toggleErrorDialog = () => {
        setShowErrorDialog(!showErrorDialog);
    }

    return (
        <FeedbackContainer>
            <Title>Share Your Feedback</Title>
            <SharedErrorDialog 
                $error={true}
                $show={showErrorDialog}
                message={errorMessage}
                toggleDialog={toggleErrorDialog}
            />
            <SharedErrorDialog
                $error={false}
                $show={showConfirmationDialog}
                message={confirmationMessage}
                toggleDialog={toggleConfirmationDialog}
            />
            <RatingContainer container>
                <QuestionLabel>What do you think about our site?</QuestionLabel>
                <RatingStyled
                    aria-required
                    aria-label={"site-rating"}
                    name={"site-rating"}
                    value={rating}
                    onChange={handleRatingChange}
                />
            </RatingContainer>
            <FeedbackContainer>
                <QuestionLabel>Please share your feedback and ideas on how we can improve the site.</QuestionLabel>
                <TextField
                    multiline
                    rows={4}
                    value={feedback}
                    label={"Please share any feedback you have!"}
                    inputProps={{ maxLength: 150 }}
                    onChange={handleFeedbackChange}
                    fullWidth
                />
            </FeedbackContainer>
            
            <ButtonWrapper>
                <SharedButton
                    buttonType={"Action"}
                    width={"Short"}
                    onClick={onSubmitFeedback}
                >
                    Submit
                </SharedButton>
            </ButtonWrapper>
        </FeedbackContainer>
    )
}

export default SiteFeedback;