import { Grid } from '@mui/material';
import React from 'react';
import AllGroupSessions from '../../components/groupsessions/AllGroupSessions';
import SharedButton from '../../components/shared/SharedButton/SharedButton';
import SharedDrawerMentee from '../../components/shared/SharedDrawerMentee/SharedDrawerMentee';
import { Title, TitleWrapper } from './styles';
import Login from '../../components/login/Login';

function Meetings({ setToken, token }) {
    return token ? (
        <>
            <SharedDrawerMentee token={token} />
            <Grid container padding={"0 4vw"}>
                <TitleWrapper container>
                    <Grid item xs={8}>
                        <Title>Group Sessions Available</Title>
                    </Grid>
                </TitleWrapper>

                <AllGroupSessions token={token} />
            </Grid>
        </>
    ) : (
        <Login setToken={setToken} />
    )
}

export default Meetings;