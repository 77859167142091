import { Grid, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";


// Grid
export const BasicInformationContainer = styled(Grid)`
    && {
        padding: 10px 0;
    }
`;

export const ProfileInformationContainer = styled(Grid)`
    && {
    }
`;

export const RegistrationContainer = styled(Grid)`
    && {
        margin: 15vh auto 8vh;
        width: 45%;
        padding: 0 auto;
        min-width: 850px;
    }
`;

export const TitleContainer = styled(Grid)`
    && {
        display: flex;
        justify-content: flex-start;
        margin: 25px 0 10px;
    }
`;

export const NameContainer = styled(Grid)`
    && {

    }
`;

export const NameWrapper = styled(Grid)`
    && {
        padding: 20px 0;
    }
`;

export const InputContainer = styled(Grid)`
    && {
        padding: 20px 0;
    }
`;

export const ButtonContainer = styled(Grid)`
    && {
        display: flex;
        justify-content: flex-end;
    }
`;

// Typography
export const Title = styled(Typography)`
    && {
        font-size: ${FontSize.header3};
        text-transform: capitalize;
        font-weight: 500;
    }
`;

export const Label = styled(Typography)`
    && {
        text-transform: capitalize;
        font-size: ${FontSize.header4};
        font-weight: 400;
        margin-bottom: 10px;
    }
`;

export const CharacterCount = styled(Typography)`
    && {
        font-size: ${FontSize.body};
        color: ${Color.grey80};
        position: relative;
        margin-top: -30px;
        text-align: right;
        width: 98%;
        z-index: 100;
    }
`

// Misc.
export const Input = styled('input')({
    display: 'none',
});