import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { baseURL } from '../../config/config';
import { Button, Card, CardActionArea, CardContent, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import Color from "../../assets/colors";
import Milestone from "./Milestone";
import { PlanOfActionWidget, Title } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import TextField from '@mui/material/TextField';
import { FieldWrapper,
FormLabel,
HeaderOne,
HeaderTwo,
HeaderThree,
CreateMeetingWrapper,
SelectWrapper } from "./styles";

// async function getMmid(params) {
//     // first get logged in user's mentorid
// 	const auth_header = "Bearer " + params.loginToken;
// 	const request = {
// 		method: 'GET',
// 		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
// 	};

// 	var response = await fetch(baseURL + "/profile/mentee/mmid", request)
// 	return response.json();
// }

async function submitMilestone(params) {
	const auth_header = "Bearer " + params.loginToken;
	const toSubmit = {
		"name": params.milestoneValues.name
	};

	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
		body: JSON.stringify(toSubmit)
	};

	var response = await fetch(baseURL + "/feedback/milestone", request)
	return response.json();
}

const defaultValues = {
    name: "",
    submitText: "Submit"
};

const AddMilestone = ({ token }) => {
	const [values, setValues] = useState(defaultValues);

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setValues({
            ...values,
            [name]: value
        });
	};

	var handleSubmit = async (e) => {
		e.preventDefault();

		const res = await submitMilestone({ loginToken: token, milestoneValues: values });
		if (res.responseCode === 200) {
			setValues({...values, submitText: "Saved 😁"});
		} else {
			setValues({...values, submitText: "Error 😞"});
		}
	}

	return (
		<>
			<CreateMeetingWrapper container>
				<Grid>
					<Grid>
						<HeaderOne>Add a New Milestone</HeaderOne>
					</Grid>
					<Grid>
						<Grid>

							<FieldWrapper>				
								<FormLabel>Milestone name</FormLabel>
								<TextField 
									id="name"
									name="name"
									label="Name"
									type="text"
									value={values.name}
									onChange={handleInputChange}
									fullWidth
									required
								/>
							</FieldWrapper>

							<Button
								onClick={handleSubmit}
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								{values.submitText}
							</Button>

						</Grid>
					</Grid>
				</Grid>

			</CreateMeetingWrapper>
		</>
	)
}

export default AddMilestone;