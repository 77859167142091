import { Grid, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";

// Grid
export const RegistrationContainer = styled(Grid)`
    && {
        margin: 15vh auto 8vh;
        width: 45%;
        padding: 0 auto;
        min-width: 850px;
    }
`;

export const TitleContainer = styled(Grid)`
    && {
        display: inline-box;
        margin: 25px 0 10px;
    }
`;

export const InputContainer = styled(Grid)`
    && {
        padding: 20px 0;
    }
`;

// Typography
export const Title = styled(Typography)`
    && {
        text-transform: capitalize;
        font-size: ${FontSize.header3};
        font-weight: 500;
    }
`;

export const Label = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
        font-weight: 400;
        margin-bottom: 10px;
    }
`;