import React from "react";
import { BodyText, ButtonWrapper, CreateGroupSessionContainer, Subtitle, TextWrapper, Title } from "./styles";
import SharedButton from "../../../shared/SharedButton/SharedButton";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

function CreateGroupSessionWidget(props) {
    const { area } = props;
    const navigate = useNavigate();

    return (
        <CreateGroupSessionContainer minWidth={355}>
                <Grid>
                    <Title>Create a Group Session</Title>
                    <Subtitle>Topic areas of experience:</Subtitle>
                    <BodyText>{area ? area : "Leadership, Communication"}</BodyText>
                </Grid>
                <ButtonWrapper>
                    <SharedButton buttonType="Action" width="Short" onClick={() => navigate("/mentor/createmeeting")}>Create</SharedButton>
                </ButtonWrapper>
        </CreateGroupSessionContainer>
    )
}

export default CreateGroupSessionWidget;