import React from "react";
import { useLocation, useMatch, useParams } from "react-router-dom";
import SharedDrawerMentee from "../../../components/shared/SharedDrawerMentee/SharedDrawerMentee";
import TerminateRelationship from '../../../components/shared/TerminateRelationship/MenteeTerminate';
import User from "../../../components/user/User";

function MentorPage() {
    const params = useParams();
    const { state } = useLocation();
    const userid = params?.id;
    const token = JSON.parse(localStorage.getItem('token'));

    return (
        <>
            <SharedDrawerMentee token={token}/>
            <User
                userId={userid}
                role={"mentor"}
                view={"page"}
                token={token}
                mode={"mentee"}
            />
            <TerminateRelationship token={token}/>
        </>
    );
}

export default MentorPage;