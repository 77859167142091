import {
    Grid,
    Link,
    Box,
    Typography
} from '@mui/material';
import styled from 'styled-components';
import Color from '../../../assets/colors';
import { FontSize } from '../../../assets/font';

// Grid
export const MainActionsWrapper = styled(Grid)`
    && {
        margin: 10px 0;
    }
`;

export const LinksWrapper = styled(Grid)`
    && {
        margin: 40px;
    }
`;

export const LogoWrapper = styled(Grid)`
    && {
        background-color: ${Color.sapphire100};
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        padding: 10px;
    }
`

export const SettingsWrapper = styled(Grid)`
    && {
        position: absolute;
        margin: 40px;
        bottom: 1vh;
    }
`

export const IconContainer = styled(Grid)`
    && {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const IconTextContainer = styled(Grid)`
    && {
        margin: ${(props) => props.space == "true" ? "25px 0" : "10px 0"};
        display: flex;
        color: ${Color.white};
        align-items: center;
    }
`;

// Typography
export const Title = styled(Typography)`
    && {
        font-size: ${FontSize.header4};
        font-weight: 400;
        color: ${Color.white};
    }
`;

export const Label = styled(Typography)`
    && {
        color: ${Color.white};
    }
`;

// Box
export const DrawerContainer = styled(Box)`
    && {
        padding: 0 30px 30px;
        background-color: ${Color.sapphire100};
        height: 100vh;
    }
`;

export const TopBar = styled(Box)`
    && {
        background-color: ${Color.sapphire100};
        height: 8vh;
        position: sticky;
        width: 100vw;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        z-index: 100;
    }
`

// Link
export const LinkStyled = styled(Link)`
    && {
        text-transform: capitalize;
        font-size: ${FontSize.body};
        color: ${Color.white};
        font-color: ${Color.white};
        margin: 0 15px;
    }
`;