import React from 'react';
import Menteecreatemeeting from '../../components/menteecreatemeeting/Menteecreatemeeting';
//import SharedSidebar from '../../components/shared/SharedSidebar/SharedSidebar'
import Login from '../../components/login/Login';
import { baseURL } from '../../config/config';
import { Title, TitleWrapper } from './styles';
import SharedButton from '../../components/shared/SharedButton/SharedButton';
import SharedDrawerMentee from '../../components/shared/SharedDrawerMentee/SharedDrawerMentee';
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Navigate } from 'react-router-dom';

async function checkMentor(token) {

    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },

    };
    /*fetch(baseURL + "/profile", request)
        .then(res => res.json())*/
    fetch(baseURL + "/profile", request)
        .then(function (response) { return response.json(); })
}

function MenteeCreateMeeting({ setToken, token }) {
    useEffect(() => {
        (async () => {
            let res = await checkMentor(token);
        })();
    }, []);

    if (!token) {
        return <Navigate to='/login' />
    }

    return (
        <>
            <SharedDrawerMentee token={token} />
            <Grid container padding={"0 4vw"}>
                <TitleWrapper container>
                    <Grid item xs={8}>
                        <Title>Book a meeting</Title>
                    </Grid>
                </TitleWrapper>
                <Menteecreatemeeting token={token}></Menteecreatemeeting>
            </Grid>
        </>
    );

}

export default MenteeCreateMeeting;
