import React, { useEffect, useState } from "react";
import { baseURL } from "../../../config/config";
import User from "../../user/User";
import Mentee from "../Mentee";
import { MenteeListWrapper } from "./styles";

async function getMentees(token) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

    return fetch(baseURL + "/matching/mymentees", request)
        .then(res => res.json());
}

function MyMentees() {
    const [token, setToken] = useState("");
    const [mentees, setMentees] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const cToken = JSON.parse(localStorage.getItem('token'));

        if (!cToken) {
            window.location.href = '/login';
            return;
        }
        setToken(cToken);

        setIsLoading(true);

        getMentees(cToken)
            .then((res) => {
                if (res.responseCode !== 200) {
                    console.log("Unable to get mentees.")
                    return;
                }
                setMentees(res.data);
            })
        setIsLoading(false);
    }, []);

    return (
        <MenteeListWrapper>
            {
                !isLoading && mentees ?
                    mentees.map((mentee) => {
                        return (
                            <Mentee
                                id={mentee.userid}
                                name={mentee.firstname + " " + mentee.secondname}
                                area={mentee.topicname}
                                profile={mentee.profilepicture}
                                key={mentee.userid}
                            />
                        )
                    }) : null
            }
        </MenteeListWrapper>
    );
}

export default MyMentees;