import { Box, CardContent, Grid, Rating, TextField, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useState } from "react";
import SharedDrawerMentee from '../../components/shared/SharedDrawerMentee/SharedDrawerMentee';
import { 
    Title, 
    TitleWrapper,
    StatusWrapper,
    StatusText, 
} from './styles';
import { baseURL } from '../../config/config';
import Stack from '@mui/material/Stack';
import { CardStyled, CardSubtitle } from '../../components/meetings/styles';
import { Subtitle } from '../../components/notifications/styles';
import { ButtonWrapper, FieldWrapper } from '../../components/login/styles';
import SharedDrawerMentor from '../../components/shared/SharedDrawerMentor/SharedDrawerMentor';
import User from '../../components/user/User';
import { convertDate } from "../../util";
import SharedButton from '../../components/shared/SharedButton/SharedButton';

async function getMentor(token, id) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

    var response = await fetch(baseURL + "/profile/mentor/" + id, request);
    var json = await response.json() ?? {};

    if (json.responseCode !== 200) return null;
    return json.data;
}

async function joinLeave(token, id, join) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
    };

    var response = await fetch(baseURL + "/event/" + (join ? "join" : "leave") + "/" + id, request);
    var json = await response.json() ?? {};

    return json.responseCode === 200;
}

async function submitFeedback(token, id, r1, r2, r3) {
    const auth_header = "Bearer " + token;

    const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
        body: JSON.stringify({ relevanceRating: r1 ?? 0, contentRating: r2 ?? 0, clarityRating: r3 ?? 0 })
    };

    var response = await fetch(baseURL + "/event/feedback/" + id, request);
    var json = await response.json() ?? {};

    return json.responseCode === 200;
}

async function markSessionComplete(token, id, feedback) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
        body: JSON.stringify({ notes: feedback ?? "" })
    };

    var response = await fetch(baseURL + "/event/complete/" + id, request);
    var json = await response.json() ?? {};

    return json.responseCode === 200;
}

function Mentor(props) {
    const { token, id, mode } = props;

    const [mentor, setMentor] = useState(null);

    useEffect(() => {
        (async () => {
            let m = await getMentor(token, id);
            if (m !== null) setMentor(m);
        })();
    }, []);

    return (
        <>
            {mentor &&
                <User
                    userId={mentor?.userid}
                    role={"mentor"}
                    view={"card"}
                    type={"view"}
                    mode={mode}
                />}

        </>
    )

    // return (
    //     <CardStyled variant="outlined">
    //         <CardContent>
    //             <Grid container spacing={2}>
    //                 <Grid item>
    //                     <Grid container>
    //                         <AvatarContainer item>
    //                             <Avatar sx={{ width: 50, height: 50 }} alt="Profile picture" src={mentor?.profilepicture}>R</Avatar>
    //                         </AvatarContainer>
    //                         <CardTextContainer item>
    //                             <CardTitle>{mentor ? mentor.fullname : "..."}</CardTitle>
    //                             <CardSubtitle>{mentor ? mentor.department : "..."}</CardSubtitle>
    //                         </CardTextContainer>
    //                     </Grid>
    //                 </Grid>
    //                 <Grid item>
    //                     <ButtonWrapper item>
    //                         <SharedButton buttonType="Action" width="Short">View</SharedButton>
    //                     </ButtonWrapper>
    //                 </Grid>
    //             </Grid>
    //         </CardContent>
    //     </CardStyled>
    // )
}

function ViewSession({ token }) {
    const { state } = useLocation();

    let { id, title, date, venue, mentorid, finishdate, type } = state.session;
    let mode = state.mode;

    let [status, setStatus] = useState(state.session.status);
    let [feedback, setFeedback] = useState("");
    let [noParticipants, setNoParticipants] = useState(state.session.participants.length);
    let [isparticipant, setIsParticipant] = useState(state.session.isparticipant);

    let [submitText, setSubmitText] = useState("Submit");

    let [r1, setR1] = useState(0);
    let [r2, setR2] = useState(0);
    let [r3, setR3] = useState(0);

    return (
        <>
            {mode === "mentor" &&
                <SharedDrawerMentor token={token} />
            }
            {mode === "mentee" &&
                <SharedDrawerMentee token={token} />
            }
            <Grid container padding={"0 4vw"}>
                <TitleWrapper container>
                    <Grid item xs={8}>
                        <Stack direction="row" spacing={10}>
                            <Title>Session Details</Title>
                        </Stack>
                    </Grid>
                </TitleWrapper>
                <Box padding={4} paddingBottom={0} width={1}>
                    <CardStyled variant="outlined">
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid container item xs={12} justifyContent={"flex-end"}>
                                    <StatusWrapper status={status} item xs={12}>
                                        <StatusText status={status}>{(status ? (status.replace("Pending", "Waiting for ")) : "")}</StatusText>
                                    </StatusWrapper>
                                    <CardSubtitle>{"Description: " + (title ? title : "Untitled Meeting")}</CardSubtitle>
                                    <CardSubtitle>{"Date & Time: " + (date ? convertDate(date) : "")}</CardSubtitle>
                                    <CardSubtitle>{"Venue: " + (venue ? venue : "")}</CardSubtitle>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardStyled>
                </Box>
                <Grid container>
                <Grid paddingLeft={4} paddingRight={4} item>
                    <Subtitle>Hosted by:</Subtitle>
                    <Mentor token={token} id={mentorid} mode={mode} />
                </Grid>
                {mode === "mentee" && new Date() >= new Date(finishdate) && isparticipant &&
                    <Box>
                        <Title padding={4} paddingBottom={0}>Feedback</Title>
                        <FieldWrapper padding={4} paddingTop={0}>
                            <Subtitle>Was the advice/support given relevant and helpful?</Subtitle>
                            <Rating
                                name={""}
                                onChange={(e) => setR1(e.target.value)}
                            />
                            <Subtitle>Was the advice/support given clear?</Subtitle>
                            <Rating
                                name={""}
                                onChange={(e) => setR2(e.target.value)}
                            />
                            <Subtitle>After this meeting, do you have a clear view of your next steps?</Subtitle>
                            <Rating
                                name={""}
                                onChange={(e) => setR3(e.target.value)}
                            />
                            <Box marginTop={2}>
                                <SharedButton buttonType="Action" width="Long" onClick={async () => {
                                    let success = await submitFeedback(token, id, parseInt(r1), parseInt(r2), parseInt(r3));
                                    if (success) setSubmitText("Sent!");
                                    else setSubmitText("Missing Rating");
                                }}>{submitText}</SharedButton>
                            </Box>
                        </FieldWrapper>
                    </Box>
                }
                {status === "Confirmed" && type !== "1-1" && new Date(finishdate) > new Date() &&
                    <Box padding={4}>
                        <Title paddingBottom={0}>Participation</Title>
                        <Subtitle>{noParticipants} mentee{noParticipants === 1 ? " has" : "s have"} signed up to this session.</Subtitle>
                        {mode === "mentee" &&
                            <SharedButton buttonType="Action" width="Long" onClick={async () => {
                                let result = await joinLeave(token, id, !isparticipant);
                                if (!result) return; 

                                if (isparticipant) {
                                    setNoParticipants(noParticipants - 1);
                                }
                                else {
                                    setNoParticipants(noParticipants + 1);
                                }

                                setIsParticipant(!isparticipant);
                            }}>{isparticipant ? "Leave" : "Sign up"}</SharedButton>
                        }
                    </Box>
                }
                {mode === "mentor" && status === "Confirmed" &&
                    <Grid padding={4} item xs>
                        <Title paddingBottom={2}>Management</Title>
                        {new Date(finishdate) > new Date() && <Typography marginBottom={2}>You can send feedback once the session has ended.</Typography>}
                        <TextField
                            label="Session feedback"
                            multiline
                            rows={5}
                            disabled={new Date(finishdate) > new Date()}
                            onChange={((e) => setFeedback(e.target.value))}
                            sx={{width: "100%"}}
                        />
                        <Box>
                            <ButtonWrapper>
                                <SharedButton
                                    buttonType="Action"
                                    width="Long"
                                    disabled={new Date(finishdate) > new Date()}
                                    onClick={async () => {
                                    let success = await markSessionComplete(token, id, feedback);
                                    if (success) setStatus("Complete");
                                }}>Mark as Complete</SharedButton>
                            </ButtonWrapper>
                        </Box>
                    </Grid>
                }
                </Grid>
            </Grid>
        </>
    );
}

export default ViewSession;