import React, {useState, useEffect} from 'react';
import { Divider, Grid } from "@mui/material";
import Meeting from "./Meeting";
import { Date, DateSessions, MyMeetingsWidget, Title } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import { baseURL } from '../../config/config';
import { convertDate } from '../../util';
import SharedDate from '../shared/SharedDate/SharedDate';
import { useNavigate } from 'react-router-dom';

async function getMeetings(token, mentor) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

    var response = await fetch(baseURL + "/event/" + (mentor ? "mentor" : "mentee")  + "/mysessions", request);
    var json = await response.json() ?? {};

    if (json.responseCode !== 200) return [];

    
    return json.data.slice(0, 5); // only get the first 5
}

function MyMeetingsHome({ token, mentor }) {

    const navigate = useNavigate();
    const [meetings, setMeetings] = useState([]);
    useEffect(() => {
        (async () => {
            let res = await getMeetings(token, mentor);
            if (res.length != 0) setMeetings(res);
            console.log(res);
            if (res.length == 0) {

            }
            
        })();
    }, []);

    return (
        <MyMeetingsWidget minWidth={355}>
            <Grid container>
                <Grid item xs>
                    <Title>My Meetings</Title>
                </Grid>
                <Grid item>
                    <SharedButton buttonType="Action" width="Short" onClick={() => navigate((mentor ? "/mentor/" : "/mentee/") + "meetings")}>View All</SharedButton>
                </Grid>
            </Grid>
            <DateSessions container>
                <Divider />
                {meetings.map((meeting) => {  
                    return (<Meeting
                        title={meeting.description}
                        date={meeting.proposeddate}
                        venue={meeting.location}
                        mentorid={meeting.mentorid}
                        status={meeting.status}
                        mode={(mentor ? "mentor" : "mentee")}
                        id={meeting.sessionid}
                        key={meeting.sessionid}
                        participants={meeting.participants}
                        isparticipant={meeting.userIsParticipant}
                        finishdate={meeting.finishdate}
                        type={meeting.type}
                    />) 
                })}
            </DateSessions>
        </MyMeetingsWidget>
    )
    
}

export default MyMeetingsHome;