import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { convertDate } from '../../util';
import {
	FieldWrapper,
	FormLabel,
	HeaderOne,
	HeaderThree,
	CreateMeetingWrapper
} from "./styles";
import {baseURL} from "../../config/config";
// Call API to get user's name and image
async function getUserInfo(params) {
	const auth_header = "Bearer " + params.loginToken;
	const request = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
	};

	var response = await fetch(baseURL + "/profile", request)
	return response.json();
}

const MenteeProfile = ({ token }) => {
	const [firstname, setFirstname] = useState('');
	const [secondname, setSecondname] = useState('');
	const [email, setEmail] = useState('');
	const [jobposition, setJobposition] = useState('');
	const [jobdatestarted, setJobdatestarted] = useState('');
	const [location, setLocation] = useState('');
	const [department, setDepartment] = useState('');
	const [image, setImage] = useState('');
	const [bio, setBio] = useState('');

	useEffect(() => {
		getData();
	}, []);

	var getData = async () => {
		const res = await getUserInfo({ loginToken: token });
		if (res.responseCode === 200) {
			setFirstname(res.data.firstname);
			setSecondname(res.data.secondname);
			setEmail(res.data.email);
			setJobposition(res.data.jobposition);
			setJobdatestarted(res.data.jobdatestarted);
			setLocation(res.data.location);
			setDepartment(res.data.department);
			setImage(res.data.profilepicture); // if invalid, default profile picture will be used
			setBio(res.data.menteeProfile.bio);
		}
	};

	return (
		<>
			<CreateMeetingWrapper container>
				<Grid>
					<Grid>
						<HeaderOne>{firstname}'s mentee profile</HeaderOne>
					</Grid>
					<Grid>
						<Grid>

							<FieldWrapper>	
								<Box display="flex" flexDirection="row" alignItems="center"
									sx={{ border: 1, borderColor: '#000000', backgroundColor: '#E3F2FD',
									borderRadius: 3, padding: 5, margin: 5, boxShadow: 3 }}
								>
									<Stack direction="row" spacing={2}>
										<Avatar src={image} alt={firstname} sx={{ width: 260, height: 260 }} />
										<Stack direction="column" spacing={2}>
											<HeaderThree>{firstname} {secondname}</HeaderThree>
											<Stack direction="row" spacing={2}>
												<WorkIcon sx={{width: 36, height: 36}} />
												<FormLabel>{jobposition} in {department}</FormLabel>
											</Stack>
											<Stack direction="row" spacing={2}>
												<CalendarTodayIcon sx={{width: 36, height: 36}} />
												<FormLabel>Started {convertDate(jobdatestarted)}</FormLabel>
											</Stack>
											<Stack direction="row" spacing={2}>
												<LocationOnIcon sx={{width: 36, height: 36}} />
												<FormLabel>{location}</FormLabel>
											</Stack>
											<Stack direction="row" spacing={2}>
												<EmailIcon sx={{width: 36, height: 36}} />
												<FormLabel>{email}</FormLabel>
											</Stack>
										</Stack>
									</Stack>
								</Box>
							</FieldWrapper>
							<FieldWrapper>
								<Box display="flex" flexDirection="row" alignItems="center" 
									sx={{ border: 1, borderColor: '#000000',
									borderRadius: 3, padding: 2, margin: 5, boxShadow: 3 }}
								>	
									<Stack direction="column" spacing={2}>
										<HeaderThree>Profile Description</HeaderThree>
										<FormLabel>{bio}</FormLabel>
									</Stack>
								</Box>
							</FieldWrapper>

						</Grid>
					</Grid>
				</Grid>

			</CreateMeetingWrapper>
		</>
	)
}

export default MenteeProfile;