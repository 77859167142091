import React from "react";
import { 
    CloseIconStyled, 
    ErrorDialogContainer, 
    ErrorText, 
    IconContainer 
} from "./styles";

function SharedErrorDialog(props) {
    const {
        message,
        $show,
        $error,
        toggleDialog
    } = props;

    return (
        <ErrorDialogContainer $show={$show} $error={$error}>
            <IconContainer onClick={toggleDialog}>
                <CloseIconStyled />
            </IconContainer>
            <ErrorText>{message}</ErrorText>
        </ErrorDialogContainer>
    )
}

export default SharedErrorDialog;