import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import GroupSession from '../../components/groupsessions/widgets/GroupSessionList/GroupSession';
import MyMeetingsHome from '../../components/meetings/MyMeetingsHome';
import MyMentor from '../../components/mentor/MyMentor';
import PlanOfActionBox from '../../components/planofaction/PlanOfActionBox';
import SharedButton from '../../components/shared/SharedButton/SharedButton';
import SharedDrawerMentee from '../../components/shared/SharedDrawerMentee/SharedDrawerMentee';
import NotificationList from '../../components/notifications/NotificationList';
import { baseURL } from '../../config/config';
import { Title, TitleWrapper } from './styles';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import SharedErrorDialog from '../../components/shared/SharedErrorDialog/SharedErrorDialog';

async function getMentor(token) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

    var response = await fetch(baseURL + "/matching/mymentor", request);
    var json = await response.json() ?? {};

    if (json.responseCode !== 200) return null;
    return json.data;
}

function MenteeHome({ setToken, token }) {
    const { state } = useLocation();
    const [show, setShow] = useState(false);
    const [mentor, setMentor] = useState(0); // use 0 to denote not loaded (so button never shows up until request is complete)
    const navigate = useNavigate();

    if (state?.message != null && !show) {
        setShow(true);
    }

    useEffect(() => {
        if (!token) return;
        (async () => {
            let mentor = await getMentor(token);
            if (mentor != null) setMentor(mentor);
        })();
    }, []);

    if (!token) {
        return <Navigate to='/login' />
    }


    return (
        <>
            <SharedDrawerMentee token={token} />
            <Grid container padding={"0 4vw"}>
                <TitleWrapper container>
                    <Grid item xs={12}>
                        <Title>Dashboard</Title>
                        <SharedErrorDialog
                            $error={false}
                            message={state?.message ?? ""}
                            $show={show}
                            toggleDialog={() => { state.message = null; setShow(false) }} />
                    </Grid>
                </TitleWrapper>
                <Grid container justifyContent={"center"}>
                    <MyMentor item xs={6} mentor={mentor === 0 ? null : mentor} />
                    <NotificationList item xs={6} token={token} />
                    <MyMeetingsHome item xs={6} token={token} mentor={false} />
                    <PlanOfActionBox item xs={6} token={token}/>
                    <GroupSession item xs={6} token={token} />
                </Grid>
            </Grid>
        </>
    );
}

export default MenteeHome;