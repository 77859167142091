import React, {useState, useEffect} from 'react';
import { Grid, Box } from "@mui/material";
import Milestone from "./Milestone";
import { PlanOfActionWidget, Title } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import { useNavigate } from "react-router-dom";
import { baseURL } from '../../config/config';

async function getPlan(params) {
	const auth_header = "Bearer " + params.loginToken;
	const request = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
	};
	var response = await fetch(baseURL + "/feedback/myplan", request)
	return response.json();
}

const PlanOfActionBox = ({ token }) => {
    //const { title, tasks } = props;
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [milestoneIDs, setMilestoneIDs] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    var getData = async () => {
        const res = await getPlan({ loginToken: token });
        if (res.responseCode === 200) {
            setTasks(res.data);
            setMilestoneIDs([...new Set(res.data.map(task => task.milestoneid))]);
        }
    };

    return (
        <PlanOfActionWidget minWidth={355}>
            <Grid container spacing={2}>
                <Grid item xs>
                    <Title>Plan of Action</Title>
                </Grid>
                <Grid item xs="auto">
                    <SharedButton buttonType="Action" width="Short" onClick={() => navigate("/mentee/planofaction")}>View All</SharedButton>
                </Grid>
            </Grid>
            <Grid container>
                {milestoneIDs.slice(0,5).map(mid => (
                    <Grid item xs={12}>
                        <Milestone milestoneID={mid} tasks={tasks} token={token}/>
                    </Grid>
                ))}
            </Grid>
        </PlanOfActionWidget>
    )
}

export default PlanOfActionBox;