import React from "react";
import SiteFeedback from "../../components/feedback/SiteFeedback";
import { useLocation } from "react-router-dom";
import SharedDrawerMentee from "../../components/shared/SharedDrawerMentee/SharedDrawerMentee";
import SharedDrawerMentor from "../../components/shared/SharedDrawerMentor/SharedDrawerMentor";

function Feedback() {
    const role = localStorage.getItem("role");
    const token = JSON.parse(localStorage.getItem('token'));

    return (
        <>
            {
                role === "mentor" ? <SharedDrawerMentor token={token} />
                    : <SharedDrawerMentee token={token} />
            }
            <SiteFeedback />
        </>
    )
}

export default Feedback;